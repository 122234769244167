import { CodeType } from '../../types'

export function codeTypeFixture({
  offer_type = 'prepaid',
  error_code = undefined,
  error_params = undefined
} = {}): CodeType {
  return {
    offer_type,
    error_code,
    error_params
  }
}

import React, { FC, useEffect } from 'react'
import { useAnalytics, useFlowManager, useSignupSession } from '../../../hooks'
import { useLocation } from 'react-router-dom'

export const AnalyticsEventHandler: FC = ({ children }) => {
  const { pathname } = useLocation()
  const flowManager = useFlowManager()
  const { partialSubscriptionId } = useSignupSession()
  const { publishDisplayEvent, publishClickEvent } = useAnalytics()

  useEffect(() => {
    if (
      partialSubscriptionId !== undefined &&
      flowManager.trackDisplayEvent(flowManager.stepFromPath(pathname))
    ) {
      publishDisplayEvent({ pathname })
    }
  }, [pathname, flowManager, partialSubscriptionId, publishDisplayEvent])

  return (
    <div
      onClick={(event) => {
        publishClickEvent({ eventTarget: event?.target })
      }}
    >
      {children}
    </div>
  )
}

import { Benefits } from '../../types'

export function benefitsFixture({
  freeTrialMonths = 3,
  promoCodeMonths = 4,
  referAFriendMonths = 1,
  enrollmentKeyMonths = 2,
  prepaidMonths = 5,
  prepaidBalanceCents = 6000,
  prepaidBalanceFormatted = '$60.00',
  totalMonths = 7,
  trialEndDate = '10/26/3000',
  totalFreeMonths = 4,
  postTrialFlipMonths = 0
} = {}): Benefits {
  return {
    freeTrialMonths,
    promoCodeMonths,
    referAFriendMonths,
    enrollmentKeyMonths,
    prepaidMonths,
    prepaidBalanceCents,
    prepaidBalanceFormatted,
    totalMonths,
    trialEndDate,
    totalFreeMonths,
    postTrialFlipMonths
  }
}

export function replacementBenefitsFixture({
  freeTrialMonths = 3,
  promoCodeMonths = 4,
  referAFriendMonths = 2,
  enrollmentKeyMonths = 1,
  prepaidMonths = 5,
  prepaidBalanceCents = 6000,
  prepaidBalanceFormatted = '$60.00',
  trialEndDate = '10/26/3000',
  postTrialFlipMonths = 0,
  printerReplacementMonths = 0,
  replacementTotalPrepaidBalanceCents = 12000,
  replacementTotalPrepaidFormatted = '$120.00',
  hasTransferringMonths = true,
  paperFreeMonths = 0,
  supportAwardMonths = 6,
  noTotalMonths = false
} = {}): Benefits {
  const transferringTotalFreeMonths =
    freeTrialMonths +
    promoCodeMonths +
    referAFriendMonths +
    printerReplacementMonths +
    supportAwardMonths +
    enrollmentKeyMonths
  const transferringTotalMonths = transferringTotalFreeMonths + prepaidMonths
  const totalFreeMonths = noTotalMonths
    ? 0
    : freeTrialMonths +
      promoCodeMonths +
      printerReplacementMonths +
      postTrialFlipMonths
  const transferringMonths = hasTransferringMonths
    ? {
        freeTrialMonths,
        promoCodeMonths,
        referAFriendMonths,
        printerReplacementMonths,
        supportAwardMonths,
        enrollmentKeyMonths,
        prepaidMonths,
        prepaidBalanceCents,
        prepaidBalanceFormatted,
        totalFreeMonths: transferringTotalFreeMonths,
        totalMonths: transferringTotalMonths,
        paperFreeMonths
      }
    : undefined

  const replacementTotalFreeMonths = noTotalMonths
    ? 0
    : totalFreeMonths + transferringTotalMonths
  const totalMonths = noTotalMonths
    ? 0
    : totalFreeMonths + prepaidMonths + transferringTotalMonths

  return {
    freeTrialMonths,
    promoCodeMonths,
    enrollmentKeyMonths: 0,
    referAFriendMonths: 0,
    prepaidMonths,
    prepaidBalanceCents,
    prepaidBalanceFormatted,
    totalMonths,
    trialEndDate,
    totalFreeMonths,
    postTrialFlipMonths,
    replacementTotalFreeMonths,
    printerReplacementMonths,
    replacementTotalPrepaidBalanceCents,
    replacementTotalPrepaidFormatted,
    transferringMonths
  }
}

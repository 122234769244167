const WINDOW_WIDTH = 500
const WINDOW_HEIGHT = 580

let chatbotWindow = null

export function openChatbot(e) {
  const left = screen.width / 2 - WINDOW_WIDTH / 2
  const top = screen.height / 2 - WINDOW_HEIGHT / 2

  e.preventDefault()

  if (chatbotWindow === null || chatbotWindow.closed) {
    chatbotWindow = window.open(
      `/chat_window?launch=printer_replacement&width=${document.documentElement.clientWidth}`,
      'HPChatWindow',
      `resizable=yes, scrollable=no, width=${WINDOW_WIDTH}, height=${WINDOW_HEIGHT}, left=${left}, top=${top}`
    )
  } else {
    try {
      chatbotWindow.focus()
    } catch (ex) {
      // do nothing
    }
  }
}

import { PrinterBenefits } from '../../types'

export function printerBenefitsFixture({
  deviceUuid = 'device-uuid-1',
  freeTrialMonths = 1,
  prepaidBalanceCents = 100,
  prepaidBalanceFormatted = '$1.00'
} = {}): PrinterBenefits {
  return {
    deviceUuid,
    freeTrialMonths,
    prepaidBalanceCents,
    prepaidBalanceFormatted
  }
}

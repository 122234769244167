import { Paths } from '../../types'

export function pathsFixture({
  dashboardPath = '/subscriptions',
  signOutPath = '/users/logout',
  termsPath = '/us/en/terms',
  subscriptionCancellationSMBPath = '//hpinstantink.com/cancel',
  subscriptionCancellationConsumerPath = '//pie1.hpinstantink.com/cancel',
  smbPortalPath = '//smb.portal.com/instantink/overview'
} = {}): Paths {
  return {
    dashboardPath,
    signOutPath,
    termsPath,
    subscriptionCancellationSMBPath,
    subscriptionCancellationConsumerPath,
    smbPortalPath
  }
}

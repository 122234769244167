import React, { FC } from 'react'
import * as Styled from './skeletonStyles'
import { SkeletonIcon, SkeletonText } from '../../shared/SkeletonComponents'

export const V3StepperSkeleton: FC = () => (
  <Styled.V3StepperSkeleton data-testid="v3-stepper-skeleton">
    {[1, 2, 3].map((row) => (
      <React.Fragment key={`skeleton-${row}`}>
        <SkeletonIcon className="shell-skeleton" />
        <SkeletonText
          width={92}
          backgroundColor="none"
          height={16}
          marginBottom={0}
          className="shell-skeleton"
        />
        <Styled.SkeletonLine />
      </React.Fragment>
    ))}
    <SkeletonIcon className="shell-skeleton" />
    <SkeletonText
      width={92}
      backgroundColor="none"
      height={16}
      marginBottom={0}
      className="shell-skeleton"
    />
  </Styled.V3StepperSkeleton>
)

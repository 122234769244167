import { AxiosResponse } from 'axios'
import { JarvisWebHttpClient } from '@jarvis/web-http/dist/jarvis-web-http-client'
import {
  AuthProviderType,
  BillingAddress,
  BillingInfo,
  ShippingAddress
} from '../types'

export interface IComfeService {
  getBillingInfo(): Promise<AxiosResponse<BillingInfo>>
  getBillingAddress(): Promise<AxiosResponse<BillingAddress>>
  getShippingAddress(): Promise<AxiosResponse<ShippingAddress>>
  getShippingAddressByCloudId(cloudId): Promise<AxiosResponse<ShippingAddress>>
}

export class ComfeService implements IComfeService {
  httpClient: JarvisWebHttpClient
  xCorrelationId: string
  shippingAddressEndpoint = '/shipping/addresses/default'
  shippingAddressByCloudIdEndpoint = '/shipping/addresses/devices'
  billingInfoEndpoint = '/billing'
  billingAddressEndpoint = '/billing/address'

  constructor(
    baseURL: string,
    xCorrelationId: string,
    authProvider?: AuthProviderType
  ) {
    this.httpClient = new JarvisWebHttpClient({
      defaultBaseURL: baseURL,
      defaultAuthProvider: authProvider
    })
    this.xCorrelationId = xCorrelationId
  }

  async getShippingAddress(): Promise<AxiosResponse<ShippingAddress>> {
    return this.httpClient.get({
      url: `${this.shippingAddressEndpoint}`
    })
  }

  async getShippingAddressByCloudId(
    cloudId: string
  ): Promise<AxiosResponse<ShippingAddress>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.shippingAddressByCloudIdEndpoint}/${cloudId}`
    })
  }

  async getBillingInfo(): Promise<AxiosResponse<BillingInfo>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.billingInfoEndpoint}`
    })
  }

  async getBillingAddress(): Promise<AxiosResponse<BillingAddress>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.billingAddressEndpoint}`
    })
  }

  private

  defaultHeaders() {
    return {
      headers: {
        'X-Correlation-ID': this.xCorrelationId
      }
    }
  }
}

import React, { FC } from 'react'
import { FlowManagerContext } from '../../../contexts'
import { useEffectOnce, useFlowContext } from '../../../hooks'
import { useHistory, useLocation } from 'react-router-dom'

export const FlowManagerProvider: FC = ({ children }) => {
  const flowContext = useFlowContext()
  const flowManager = flowContext.getFlowManager()
  const { stopBrowserBack } = flowContext.getOptions()
  const history = useHistory()
  const location = useLocation()

  useEffectOnce(() => {
    if (stopBrowserBack) {
      history.replace(location.pathname, { stopBrowserBack: true })
      history.push(location.pathname)

      const eventListener = ({ state }: PopStateEvent) => {
        if (state?.state?.stopBrowserBack) {
          history.goForward()
        }
      }
      window.addEventListener('popstate', eventListener)

      return () => {
        window.removeEventListener('popstate', eventListener)
      }
    }
  })

  return (
    <FlowManagerContext.Provider value={flowManager}>
      {children}
    </FlowManagerContext.Provider>
  )
}

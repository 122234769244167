import { Settings } from '../../types'

export function settingsFixture({
  enableSMBSignupDiscountedPrepay = true,
  showSupportPhone = true,
  enablePresalesChatbot = true,
  enableVirtualAgent = true,
  enableSMBHighUsagePlansBanner = true,
  enableInstantPaper = true,
  enableCurrencyUpdateBanner = true,
  maxNumberOfAddonChangesPerBillingCycle = 323151,
  enableV3LayoutPreenroll = false,
  enableMedallia = true,
  enableJarvisAfuSMB = true,
  enableNewSmbVp = true,
  enableV3EnrollEkCard = true,
  enableSMBThankYouMedalliaSurvey = true,
  enableWebEnrollStepper = true,
  enableV3WebEnrollInMFE = false,
  enableV3WebEnroll = true,
  enableEnrollBenefitsWithoutBilling = true,
  enableRedesignPrinterSelector = true,
  enableNewIILogo = true,
  enableOobeMfeForFlows = [
    'v2_moobe',
    'v2_woobe',
    'v2_ftm_moobe',
    'v2_ftm_woobe',
    'v2_printer_replacement_moobe',
    'v2_printer_replacement_woobe',
    'v3_connect_finalize',
    'v3_connect_moobe',
    'v3_connect_woobe',
    'v3_connect_post_moobe',
    'v3_connect_post_woobe',
    'mfe_oobe_connect',
    'mfe_oobe_enroll',
    'mfe_oobe_replacement',
    'mfe_oobe_warranty_replacement',
    'rebrand_responsive'
  ],
  jarvisComponentsToLoadAsMfe = ['none'],
  enableHpidSigninEnrollRedirect = false,
  enableP2KitRebrand = false,
  enableRedesignFlipFlow = false,
  enableInkPaperPlans = false,
  flipBillingRequiredStates = ['CA'],
  enableOobeInstantPaperFocusedChoiceModal = false,
  enablePriceIncreasePlans = false,
  enableVirtualAssistantString = false,
  enableJarvisConsumerPlanSelector = false,
  enableCombinedInkPaperInPreenrollForLocales = false,
  enableWebOfflinePrinterEnrollment = false,
  oobePrinterClaimStatusPollingDelay = 1,
  oobePrinterClaimStatusPollingTimeout = 1,
  enableEnrollBeforeRegister = true,
  enableShippingAutofill = false,
  enableShippingAutofillStep = false,
  enablePaymentIssueHandling = false,
  enablePaypalWebview = false,
  enableEasyEnrollSeparatedButtons = false,
  enableAllPaymentLayout = false,
  enableGPay = false,
  enableEnrollOobeWin7Alert = false,
  enrollmentDynamicYieldPaths = [],
  enrollmentDynamicYieldPathsWeb = [],
  enableDeeplinkHpxChanges = false,
  enablePaypalInSmallvilleApp = false,
  enableCountrySelectorButton = true,
  preferredLocale = 'en_US'
} = {}): Settings {
  return {
    enableSMBSignupDiscountedPrepay,
    showSupportPhone,
    enablePresalesChatbot,
    enableVirtualAgent,
    enableSMBHighUsagePlansBanner,
    enableInstantPaper,
    enableCurrencyUpdateBanner,
    maxNumberOfAddonChangesPerBillingCycle,
    enableV3LayoutPreenroll,
    enableMedallia,
    enableJarvisAfuSMB,
    enableNewSmbVp,
    enableV3EnrollEkCard,
    enableSMBThankYouMedalliaSurvey,
    enableWebEnrollStepper,
    enableV3WebEnrollInMFE,
    enableV3WebEnroll,
    enableEnrollBenefitsWithoutBilling,
    enableRedesignPrinterSelector,
    enableNewIILogo,
    enableOobeMfeForFlows,
    jarvisComponentsToLoadAsMfe,
    enableHpidSigninEnrollRedirect,
    enableP2KitRebrand,
    enableRedesignFlipFlow,
    flipBillingRequiredStates,
    enableInkPaperPlans,
    enableOobeInstantPaperFocusedChoiceModal,
    enablePriceIncreasePlans,
    enableVirtualAssistantString,
    enableJarvisConsumerPlanSelector,
    enableCombinedInkPaperInPreenrollForLocales,
    enableWebOfflinePrinterEnrollment,
    oobePrinterClaimStatusPollingDelay,
    oobePrinterClaimStatusPollingTimeout,
    enableEnrollBeforeRegister,
    enableShippingAutofill,
    enableShippingAutofillStep,
    enablePaymentIssueHandling,
    enablePaypalWebview,
    enableEasyEnrollSeparatedButtons,
    enableAllPaymentLayout,
    enableGPay,
    enableEnrollOobeWin7Alert,
    enrollmentDynamicYieldPaths,
    enrollmentDynamicYieldPathsWeb,
    enableDeeplinkHpxChanges,
    enablePaypalInSmallvilleApp,
    enableCountrySelectorButton,
    preferredLocale
  }
}

export class SessionData {
  sessionKey: string

  constructor(sessionKey: string) {
    this.sessionKey = sessionKey
  }

  set(data) {
    const allKeys = new Set()
    JSON.stringify(data, (key, value) => {
      allKeys.add(key)
      return value
    })
    const sessionData = JSON.stringify(
      data,
      (Array.from(allKeys) as string[]).sort()
    )
    window.localStorage.setItem(this.sessionKey, sessionData)
  }

  get() {
    return JSON.parse(window.localStorage.getItem(this.sessionKey) || '{}')
  }
}

import { useCallback, useEffect, useState } from 'react'
import {
  getConsumableConfigDynHref,
  getSuppliesPublicHref
} from '../lib/printerResourceHelpers'
import { useDeviceHttpProxyContext, useDispatch, useSignupSession } from '.'
import { updateSignupSession } from '../actions'

const XML_PARSER = new DOMParser()

const CDM_DEVICE = 'cdmOnly'
// const LEDM_DEVICE = 'ledmOnly'
// const HYBRID_DEVICE = 'ledmAndCdm'

export const useSupplyTypes = () => {
  const { sendDHPRequest, appSessionId } = useDeviceHttpProxyContext()
  const dispatch = useDispatch()
  const {
    printerProtocolCapability,
    supplyTypes: sessionSupplyTypes
  } = useSignupSession()
  const [supplyTypes, setSupplyTypes] = useState([])
  const [done, setDone] = useState(false)

  const _handleRequest = useCallback(
    async (payload) => {
      try {
        const response = await sendDHPRequest(payload)
        if (response?.body?.data) {
          const isString = typeof response.body.data === 'string'
          const isJson = response.body?.contentType.indexOf('json') > -1
          const isXML = response.body?.contentType.indexOf('xml') > -1

          if (isString) {
            if (response.body?.isBase64Encoded) {
              response.body.data = atob(response.body.data)
            }

            if (isJson) {
              return JSON.parse(response.body.data)
            } else if (isXML) {
              return XML_PARSER.parseFromString(response.body.data, 'text/xml')
            }
          }
        }
        return response
      } catch (error) {
        let errorMessage = error

        if (error?.body?.data) {
          errorMessage = error.body.data
        }
        return Promise.reject(errorMessage)
      }
    },
    [sendDHPRequest]
  )

  const fetch = useCallback(
    (href) => {
      const options = {
        path: href,
        sessionId: appSessionId,
        method: 'GET'
      }
      return _handleRequest(options)
    },
    [_handleRequest, appSessionId]
  )

  const fetchServicesDiscovery = useCallback(() => {
    /* NOTE: For hybrid devices, different trees may be supported for each printer. Will want to be more dynamic in future */
    const resource =
      printerProtocolCapability === CDM_DEVICE
        ? '/cdm/servicesDiscovery'
        : '/DevMgmt/DiscoveryTree.xml'
    return fetch(resource)
  }, [fetch, printerProtocolCapability])

  const fetchConsumableConfigDyn = useCallback(
    (servicesDiscovery) => {
      return fetch(getConsumableConfigDynHref(servicesDiscovery))
    },
    [fetch]
  )

  const fetchSuppliesPublic = useCallback(
    (servicesDiscovery) => {
      return fetch(getSuppliesPublicHref(servicesDiscovery))
    },
    [fetch]
  )

  const getSupplyType = (isTrial: string, isSetup: string) => {
    if (isTrial === 'true') {
      return true
    }
    return isSetup === 'true' ? 'host' : 'trade'
  }

  const getSupplyTypes = useCallback(
    async (servicesDiscovery) => {
      if (printerProtocolCapability === CDM_DEVICE) {
        try {
          const response = await fetchSuppliesPublic(servicesDiscovery)
          return response['suppliesList']
            .filter(
              ({ isTrial, isSetup }) =>
                isTrial !== undefined || isSetup !== undefined
            )
            .map(({ isTrial, isSetup }) => getSupplyType(isTrial, isSetup))
        } catch {
          return []
        }
      } else {
        try {
          const response = await fetchConsumableConfigDyn(servicesDiscovery)
          const suppliesList = response.getElementsByTagName(
            'dd:ConsumableLifeState'
          )
          const supplyArray = [...suppliesList]
          return supplyArray
            .map((supply) => ({
              isTrial: supply.getElementsByTagName('dd:IsTrial')[0]
                ?.textContent,
              isSetup: supply.getElementsByTagName('dd:IsSETUP')[0]?.textContent
            }))
            .filter(
              ({ isTrial, isSetup }) =>
                isTrial !== undefined || isSetup !== undefined
            )
            .map(({ isTrial, isSetup }) => getSupplyType(isTrial, isSetup))
        } catch {
          return []
        }
      }
    },
    [fetchConsumableConfigDyn, fetchSuppliesPublic, printerProtocolCapability]
  )

  useEffect(() => {
    if (!done) {
      if (sessionSupplyTypes === undefined && appSessionId) {
        ;(async () => {
          try {
            const servicesDiscovery = await fetchServicesDiscovery()

            const supplies = await getSupplyTypes(servicesDiscovery)

            await dispatch(
              updateSignupSession({ supplyTypes: supplies.join(',') })
            )

            setSupplyTypes(supplies)
            setDone(true)
          } catch {
            setSupplyTypes([])
            setDone(true)
          }
        })()
      } else if (sessionSupplyTypes) {
        setSupplyTypes(sessionSupplyTypes.split(','))
        setDone(true)
      }
    }
  }, [
    appSessionId,
    dispatch,
    done,
    fetchServicesDiscovery,
    getSupplyTypes,
    sessionSupplyTypes,
    supplyTypes
  ])

  return done ? supplyTypes.join(',') : undefined
}

import { FunctionComponent } from 'react'
import { FlowManager } from './flowManager'

export type FlowTheme = {
  useMobileLayoutForTabletLandscape: boolean
}

export type FlowComponents = {
  Layout: FunctionComponent<{ className?: string }>
  Session: FunctionComponent
  Container: FunctionComponent
}

export type FlowOptions = {
  showErrorPageSkipButton: boolean
  exitFlowToInstantInk: boolean
  stopBrowserBack: boolean
  allowAddPrinter: boolean
  isNativeApp: boolean
  isHpIdValuePropPage: boolean
  requirePartialSubscription: boolean
}

export type Flow = {
  getFlowManager(): FlowManager
  getBasePath(): string
  getComponents(): FlowComponents
  getOptions(): FlowOptions
  getTheme(): FlowTheme
  loadSubFlow(params: Record<string, unknown>): Promise<void>
}

export enum FlowType {
  PREENROLL = 'preenroll',
  HPID = 'hpid',
  OOBE_TEST_SETUP = 'oobe-test-setup',
  OOBE_VALUE_PROP = 'oobe-value-prop',
  OOBE_ENROLL = 'oobe-enroll',
  OOBE_EXIT = 'oobe-exit',
  OOBE_SIGN_IN = 'oobe-sign-in',
  FTM = 'ftm',
  SMB = 'smb'
}

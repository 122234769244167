import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

export const NavbarSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  background-color: #fff;
  padding: 16px 20px;
  border-bottom: 1px solid ${tokens.color.gray2};
`
export const NavbarMenuSkeleton = styled.div`
  display: flex;
  width: 372px;
`
export const NavbarMenuButtonGroupSkeleton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`

import { useContext } from 'react'
import { EnrollStepContext } from '../contexts'

export const useEnrollStepContext = () => ({
  plansToolTipKey: undefined,
  showPaperSection: true,
  showInkAndPaperPlansModal: false,
  showPlanSelectorV3: false,
  setShowPlanSelectorV3: () => null,
  ...(useContext(EnrollStepContext) || {})
})

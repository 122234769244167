import styled, { css } from 'styled-components'
import { isMobile } from '../../../lib/screenHelpers'

export const StyledContentArea = styled.div`
  && {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 40px;
    box-sizing: border-box;

    ${({ theme: { containerSize } }) =>
      isMobile(containerSize) &&
      css`
        padding: 24px;
      `}
  }
`

export const StyledStepperAndScrollableArea = styled.div`
  && {
    display: flex;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;

    ${({ theme: { containerSize } }) =>
      isMobile(containerSize) &&
      css`
        flex-direction: column;
      `}
  }
`

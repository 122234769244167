import React, {
  ComponentType,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { ThemeProvider, useTheme } from 'styled-components'
import { WindowSize, ContainerSize } from '../../../types'

const { MOBILE_MAX, TABLET_PORTRAIT_MAX, TABLET_LANDSCAPE_MAX } = WindowSize
const { MOBILE, TABLET_PORTRAIT, TABLET_LANDSCAPE, DESKTOP } = ContainerSize

export function withContainerSize<P>(WrappedComponent: ComponentType<P>) {
  return function ContainerSizeProvider(props: P) {
    const defaultContainerSize =
      window.innerWidth <= MOBILE_MAX ? MOBILE : DESKTOP
    const [containerSize, setContainerSize] = useState(defaultContainerSize)
    const containerRef = useRef<HTMLElement>()
    const parentTheme = useTheme()
    const theme = useMemo(() => {
      const layoutSize = parentTheme?.layoutSize || containerSize
      return { ...parentTheme, containerSize, layoutSize }
    }, [parentTheme, containerSize])

    useEffect(() => {
      const target = containerRef.current
      if (target) {
        const resizeObserver = new ResizeObserver((entries) => {
          entries.forEach(({ target }) => {
            const clientWidth = target.getBoundingClientRect().width
            if (clientWidth <= MOBILE_MAX) {
              setContainerSize(MOBILE)
            } else if (clientWidth <= TABLET_PORTRAIT_MAX) {
              setContainerSize(TABLET_PORTRAIT)
            } else if (clientWidth <= TABLET_LANDSCAPE_MAX) {
              setContainerSize(TABLET_LANDSCAPE)
            } else {
              setContainerSize(DESKTOP)
            }
          })
        })
        resizeObserver.observe(target)
        return () => {
          resizeObserver.unobserve(target)
        }
      }
    }, [containerRef])

    return (
      <ThemeProvider theme={theme}>
        <WrappedComponent ref={containerRef} {...props} />
      </ThemeProvider>
    )
  }
}

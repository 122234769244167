import { useSignupState } from './useSignupState'
import { useSignupSession } from './useSignupSession'
import { useCallback } from 'react'

export const useFetchPrinterClaimStatus = () => {
  const { partialSubscription } = useSignupState()
  const { instantInkService } = useSignupSession()

  return useCallback(async () => {
    try {
      const { data } = await instantInkService.fetchPrinterClaimStatus(
        partialSubscription?.data?.id
      )

      return data
    } catch (error) {
      throw {
        error: error
      }
    }
  }, [instantInkService, partialSubscription?.data?.id])
}

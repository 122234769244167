import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchSettings = () =>
  requestStateData(
    'settings',
    ({
      signupSession: { instantInkService },
      isLoggedIn,
      localization
    }: SignupState) =>
      instantInkService.fetchSettings(
        ...(isLoggedIn ? [] : [localization?.language, localization?.country])
      )
  )

export const useSettings = () =>
  useStateData('settings', fetchSettings, () => true)

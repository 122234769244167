import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchActiveUser = () =>
  requestStateData(
    'activeUser',
    ({ signupSession: { stratusService } }: SignupState) =>
      stratusService.getActiveUser()
  )

export const useActiveUser = () => useStateData('activeUser', fetchActiveUser)

export const printerClaimFailureMessage = (errorId?: string) => {
  switch (errorId) {
    case 'unrecognized_code':
    case 'unsupported_code':
    case 'expired_code':
    case 'already_claimed_code':
    case 'ineligible_printer':
    case 'offline_printer':
      return `manual_printer_claim_step.errors.${errorId}`
    case 'claim_confirmation':
      return 'manual_printer_claim_step.warnings.claim_confirmation'
    default:
      return 'manual_printer_claim_step.errors.service_unavailable_html'
  }
}

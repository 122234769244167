import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchBillingInfo = () =>
  requestStateData(
    'billingInfo',
    ({ signupSession: { comfeService } }: SignupState) =>
      comfeService.getBillingInfo()
  )

export const useBillingInfo = () =>
  useStateData('billingInfo', fetchBillingInfo)

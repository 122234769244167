import { useEffect } from 'react'
import { useSignupState } from '../../../hooks'

export const AbTesting = () => {
  const signupState = useSignupState()

  useEffect(() => {
    window['abTesting'] = {
      ...(window['abTesting'] || {}),
      ...signupState
    }
  }, [signupState])

  return null
}

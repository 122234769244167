import { DeeplinkInfo } from '../../types'

export function deeplinkInfoFixture({
  deeplink = 'deeplink.com',
  qrCode = 'IAmAQrCode'
} = {}): DeeplinkInfo {
  return {
    deeplink,
    qrCode
  }
}

import { BillingAddress, BillingInfo, PaymentType } from '../../types'

export function billingInfoFixture({
  paymentType = PaymentType.credit_card,
  creditCard = {
    cardNumber: 'XXXX-XXXX-XXXX-1234',
    cardTypeKey: 'visa',
    cardSource: 'GPay',
    displayExpirationDate: '00/00'
  },
  directDebit = {
    bankName: 'some bank',
    bankMandate: 'a mandate',
    bankMandateDate: '1/2/34',
    bankRedactedIban: 'SOME-IBAN-NUMBER-123'
  }
} = {}): BillingInfo {
  return {
    paymentType,
    directDebit,
    creditCard
  }
}

export function billingAddressFixture({
  firstName = 'John',
  lastName = 'Doe',
  street1 = '16399 W Bernardo Dr',
  city = 'San Diego',
  state = 'CA',
  zipCode = '92127',
  countryCode = 'US'
} = {}): BillingAddress {
  return {
    firstName,
    lastName,
    fullName: `${firstName} ${lastName}`,
    street1,
    city,
    state,
    zipCode,
    countryCode
  }
}

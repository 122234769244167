import React from 'react'
import { Signup } from '../src'
import { OnboardingType, ShellProps } from '../src/types'
import projectNames from '../src/configs/projectNames'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ stack, properties }) {
  const { v1 } = window.Shell as ShellProps
  const {
    analytics,
    authProvider,
    navigation,
    store,
    localization,
    serviceRouting,
    sessionInterface,
    userSession
  } = v1

  const onboarding = store?.state?.onboarding as OnboardingType

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <Signup
        stack={stack}
        properties={properties}
        onboarding={onboarding}
        analytics={analytics}
        authProvider={authProvider}
        navigation={navigation}
        localization={localization}
        sessionInterface={sessionInterface}
        userSession={userSession}
        serviceRouting={serviceRouting}
      />
    </section>
  )
}

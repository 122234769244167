import { useBillingInfo, useSettings } from '../stateData/hooks'

export const useHasBilling = () => {
  const billingInfoState = useBillingInfo()
  const { enablePaymentIssueHandling } = useSettings().data || {}

  const paymentStateOk =
    !enablePaymentIssueHandling ||
    !billingInfoState.data?.paymentState ||
    ['ok', 'fresh'].includes(billingInfoState.data?.paymentState)

  return !!billingInfoState.data?.paymentType && paymentStateOk
}

import { useCallback } from 'react'
import { useSignupSession } from './useSignupSession'

export const usePrinterBenefits = () => {
  const { instantInkService } = useSignupSession()

  return useCallback(
    async (deviceUuid) => {
      try {
        const { data } = await instantInkService.fetchPrinterBenefits(
          deviceUuid
        )

        return data
      } catch (error) {
        throw {
          error: error
        }
      }
    },
    [instantInkService]
  )
}

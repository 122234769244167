import { useEffect, useMemo } from 'react'
import { useDispatch, useSignupState } from '../hooks'
import { AsyncAction } from '../hooks/useAsyncReducer'
import { SignupAction, SignupState, StateData } from '../types'

const pendingActions = {}

export const useStateData = <T>(
  stateKey: string,
  fetchAction: () => AsyncAction<SignupAction, SignupState, T>,
  shouldFetch: (signupState: SignupState) => boolean = ({ isLoggedIn }) =>
    isLoggedIn
) => {
  const signupState = useSignupState()
  const dispatch = useDispatch()
  const stateData: StateData<T> = useMemo(() => signupState[stateKey], [
    signupState,
    stateKey
  ])

  useEffect(() => {
    const { data, loading, error } = stateData
    if (data === undefined && !loading && !error && shouldFetch(signupState)) {
      const actionKey = `${signupState.sessionData.sessionKey}_${stateKey}`
      if (pendingActions[actionKey] === undefined) {
        ;(async () => {
          try {
            pendingActions[actionKey] = dispatch(fetchAction())
            await pendingActions[actionKey]
          } catch {
            // do nothing
          } finally {
            delete pendingActions[actionKey]
          }
        })()
      }
    }
  }, [dispatch, fetchAction, shouldFetch, signupState, stateData, stateKey])

  return stateData
}

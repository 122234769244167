import { AxiosResponse } from 'axios'
import {
  AuthProviderType,
  ActiveUser,
  UserAccount,
  PrinterDetails
} from '../types'
import {
  Stack,
  UserMgtSvcClientv3,
  AccountMgtSvcClient,
  DeviceCacheApiClient
} from '@jarvis/web-stratus-client'

export interface IStratusService {
  getActiveUser(): Promise<AxiosResponse<ActiveUser>>
  getUserAccount(): Promise<AxiosResponse<UserAccount>>
  getPrinterDetails(): Promise<AxiosResponse<PrinterDetails[]>>
}

export class StratusService implements IStratusService {
  stack: Stack
  authProvider: AuthProviderType

  constructor(stack: Stack, authProvider?: AuthProviderType) {
    this.stack = stack
    this.authProvider = authProvider
  }

  getActiveUser(): Promise<AxiosResponse<ActiveUser>> {
    const client = new UserMgtSvcClientv3(this.stack, this.authProvider)
    return client.getCurrentActiveUser()
  }

  getUserAccount(): Promise<AxiosResponse<UserAccount>> {
    const client = new AccountMgtSvcClient(this.stack, this.authProvider)
    return client.getAccount()
  }

  getPrinterDetails(): Promise<AxiosResponse<PrinterDetails[]>> {
    const client = new DeviceCacheApiClient(this.stack, this.authProvider)
    return client.list()
  }
}

import React, { FC } from 'react'
import styled from 'styled-components'

type SkeletonIconProps = {
  size?: number
  marginRight?: number
  className?: string
}

const StyledSkeletonIcon = styled.div<SkeletonIconProps>`
  width: ${({ size }) => size || 36}px;
  height: ${({ size }) => size || 36}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  border-radius: 50%;
`

export const SkeletonIcon: FC<SkeletonIconProps> = (props) => (
  <StyledSkeletonIcon {...props} />
)

import { PrinterOffer } from '../../types'
import { inkPlansFixture } from './planFixtures'

export function printerOfferFixture({
  baseMonths = 3,
  technology = 'ink',
  genType = 'gen2',
  savingsPercent = '50%',
  plans = inkPlansFixture()
} = {}): PrinterOffer {
  return {
    baseMonths,
    technology,
    genType,
    savingsPercent,
    plans
  }
}

import styled, { css } from 'styled-components'
import tokens from '@veneer/tokens'
import { isMobile } from '../../../lib/screenHelpers'

interface V3ContainerProps {
  $loading?: boolean
}

export const V3Container = styled.div<V3ContainerProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${tokens.color.gray0};
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      height: 100%;
    `}

  ${({ $loading }) =>
    $loading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

export const V3StepperSkeleton = styled.div`
  display: grid;
  grid-template-columns: 36px 92px;
  grid-template-rows: repeat(4, 36px 44px);
  gap: 12px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  width: 262px;
  background-color: #fff;
  padding: 40px 32px;
  border-right: 1px solid ${tokens.color.gray2};
`

export const SkeletonLine = styled.div`
  grid-column: span 2;
  border-left: 2px solid ${tokens.color.gray2};
  margin-left: 17px;
  height: 100%;
`

import { useState, useCallback } from 'react'
import { useSignupSession } from './useSignupSession'

export const useFetchAutofillAddresses = () => {
  const { instantInkService } = useSignupSession()
  const [cache, setCache] = useState({})

  return useCallback(
    async (param, partialSubscriptionId) => {
      if (cache[param]) {
        return cache[param]
      } else {
        try {
          if (instantInkService) {
            const { data } = await instantInkService.fetchAutofillAddresses(
              param,
              partialSubscriptionId
            )
            setCache((prevCache) => ({ ...prevCache, [param]: data }))
            return data
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    [cache, instantInkService]
  )
}

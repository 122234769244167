import { AnalyticsClient, FlowType, SignupState } from '../../types'
import { UdlAnalyticsClient } from './udlAnalyticsClient'
import { CdmAnalyticsClient } from './cdmAnalyticsClient'

export const analyticsClientFactory = (
  signupState: SignupState
): AnalyticsClient => {
  let analyticsClient: AnalyticsClient
  const {
    isLoggedIn,
    analytics,
    signupSession: {
      flow,
      instantInkURL,
      isODFlow,
      analyticsBaseScreenPathName
    }
  } = signupState

  const baseScreenPath =
    analyticsBaseScreenPathName ||
    (isODFlow ? '/ReactSmbInstantInkSignup/' : '/VulcanLoader/')

  switch (flow) {
    case FlowType.SMB: {
      analyticsClient = new CdmAnalyticsClient({
        baseScreenPath,
        publishCdmEvents: analytics?.publishCdmEvents
      })
      break
    }

    default: {
      analyticsClient = new UdlAnalyticsClient({
        isLoggedIn,
        instantInkURL,
        signupState
      })
      break
    }
  }

  return analyticsClient
}

import { AddonName, PlanAddon } from '../types'

export const getPaperAddon = (addons: PlanAddon[]) =>
  addons?.find(({ name }) => name === AddonName.INSTANT_PAPER)

export const paperOptedIn = (addons: PlanAddon[]) =>
  getPaperAddon(addons)?.optedIn

export const getCartridgeType = (rawTechnology: string) => {
  return rawTechnology.toLowerCase() === 'ink' ? 'ink' : 'toner'
}

export const isInkProgram = (programType: string) =>
  programType &&
  !programType.includes('toner') &&
  !programType.includes('laser')

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms))

import { useCallback, useEffect, useRef } from 'react'

export const useIsUnmounted = () => {
  const unmountedRef = useRef(false)

  useEffect(
    () => () => {
      unmountedRef.current = true
    },
    []
  )

  return useCallback(() => unmountedRef.current, [unmountedRef])
}

import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const createPartialSubscription = (
  printerUuid: string,
  printerBizModel: string,
  printerSku: string,
  flowType: string
) =>
  requestStateData(
    'partialSubscription',
    ({ signupSession: { instantInkService } }: SignupState) =>
      instantInkService.createPartialSubscription(
        printerUuid,
        printerBizModel,
        printerSku,
        flowType
      )
  )

export const fetchPartialSubscription = (id?: string | number) =>
  requestStateData(
    'partialSubscription',
    ({
      signupSession: { instantInkService, partialSubscriptionId },
      isLoggedIn,
      localization
    }: SignupState) =>
      instantInkService.fetchPartialSubscription(
        id || partialSubscriptionId,
        ...(isLoggedIn ? [] : [localization?.language, localization?.country])
      )
  )

export const updatePartialSubscription = (params: {
  plan_id?: number
  sign_up_completion_code?: string
  printer_id?: number | string
  special_offers_code?: string
  paper_opt_in?: boolean
  afu_accepted?: boolean
  flow?: {
    flow_name: string
    account_identifier?: string
  }
  auto_claim_retry?: string
  register_printer?: boolean
  claim_printer?: {
    code: string
    confirm_transfer?: boolean
  }
}) =>
  requestStateData(
    'partialSubscription',
    ({
      signupSession: { instantInkService },
      partialSubscription
    }: SignupState) =>
      instantInkService.updatePartialSubscription(
        partialSubscription?.data?.id,
        params
      )
  )

export const usePartialSubscription = () =>
  useStateData(
    'partialSubscription',
    fetchPartialSubscription,
    ({ signupSession }) => signupSession.partialSubscriptionId !== undefined
  )

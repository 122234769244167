import {
  SignupAction,
  SignupActionType,
  SignupState,
  StateData
} from '../types'
import { AsyncDispatch } from '../hooks/useAsyncReducer'

export const updateStateData = <T>(
  stateKey: string,
  stateData: StateData<T>
) => async (dispatch: AsyncDispatch<SignupAction, SignupState>) =>
  dispatch({
    type: SignupActionType.SET_STATE_DATA,
    stateKey,
    stateData
  })

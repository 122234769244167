import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchUserAccount = () =>
  requestStateData(
    'userAccount',
    ({ signupSession: { stratusService } }: SignupState) =>
      stratusService.getUserAccount()
  )

export const useUserAccount = () =>
  useStateData('userAccount', fetchUserAccount)

import { SessionData } from '../../lib/sessionData'

export function resolve<T>(result: T, delayMS: number): Promise<T> {
  return new Promise((resolve) => setTimeout(() => resolve(result), delayMS))
}

export function reject<T>(result: T, delayMS: number): Promise<T> {
  return new Promise((_, reject) => setTimeout(() => reject(result), delayMS))
}

export function getMockFixtureOverride(sessionData: SessionData, name: string) {
  return sessionData.get()?.['mockFixtures']?.[name] || {}
}

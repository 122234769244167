export function toPascal(string: string): string {
  return string
    ?.split('-')
    ?.map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
    ?.join('')
}

export function toDashes(string: string): string {
  return string
    ?.replace(/(?!^)([A-Z])/g, '-$1')
    ?.toLowerCase()
    ?.replace(/[\s_,;:]+/g, '-')
    ?.replace(/-+/g, '-')
}

function randomNumber(): number {
  return crypto.getRandomValues(new Uint32Array(1))[0]
}

function randomID(): string {
  return [...Array(4)].map(() => randomNumber().toString(16)).join('')
}

export function createCorrelationId(): string {
  return `${randomID()}+${new Date().toISOString()}`
}

import { AxiosResponse } from 'axios'
import { IComfeService } from '../../lib/comfeService'
import { SessionData } from '../../lib/sessionData'
import { BillingInfo, BillingAddress, ShippingAddress } from '../../types'
import { resolve, getMockFixtureOverride } from './mockHelpers'
import {
  billingInfoFixture,
  billingAddressFixture,
  shippingAddressFixture
} from '../fixtures'

export class MockComfeService implements IComfeService {
  sessionData: SessionData
  defaultDelay: number

  constructor(sessionData: SessionData, defaultDelay = 0) {
    this.sessionData = sessionData
    this.defaultDelay = defaultDelay
  }

  getShippingAddress(): Promise<AxiosResponse<ShippingAddress>> {
    return resolve(
      {
        data: {
          ...shippingAddressFixture(),
          ...getMockFixtureOverride(this.sessionData, 'shippingAddress')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  getShippingAddressByCloudId(
    cloudId: string
  ): Promise<AxiosResponse<ShippingAddress>> {
    return resolve(
      {
        data: {
          ...shippingAddressFixture({ street2: cloudId }),
          ...getMockFixtureOverride(this.sessionData, 'shippingAddress')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  getBillingInfo(): Promise<AxiosResponse<BillingInfo>> {
    return resolve(
      {
        data: {
          ...billingInfoFixture(),
          ...getMockFixtureOverride(this.sessionData, 'billingInfo')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  getBillingAddress(): Promise<AxiosResponse<BillingAddress>> {
    return resolve(
      {
        data: {
          ...billingAddressFixture(),
          ...getMockFixtureOverride(this.sessionData, 'billingAddress')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }
}

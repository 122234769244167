import React, { useContext } from 'react'
import ProgressIndicator, {
  ProgressIndicatorInterface
} from '@veneer/core/dist/scripts/progress_indicator'
import { useAssetImport } from './useAssetImport'
import { MfeComponentContext } from './types'
import * as S from './styles'
import { useSignupSession } from '../../hooks'
import {
  customSemantics,
  shape,
  DENSITY,
  MODE
} from '../../flows/shared/LayoutTheme/layoutTheme'

export const MfeLoader = () => {
  const { isHpx } = useSignupSession()
  const { name, props } = useContext(MfeComponentContext)
  const { isLoading, mfe } = useAssetImport(`@jarvis/${name}`)
  const loadingAppearance = props?.loadingAppearance || 'linear'

  if (isLoading && loadingAppearance !== 'none') {
    return (
      <S.LoadingContainer>
        <ProgressIndicator
          appearance={
            loadingAppearance as ProgressIndicatorInterface['appearance']
          }
        />
      </S.LoadingContainer>
    )
  }

  if (mfe?.default) {
    const themeSettings = {
      customSemantics: customSemantics(isHpx),
      mode: MODE,
      shape: shape(isHpx),
      density: DENSITY
    }
    const updatedProps = { themeSettings, ...props }

    return <mfe.default properties={updatedProps} />
  }

  if (loadingAppearance === 'none' && isLoading) return null

  return <div>MFE not found</div>
}

import React, { useState, useCallback, FC } from 'react'
import { Button } from '../Button'
import {
  useDispatch,
  useExitFlow,
  useIsUnmounted,
  usePartialSubscription
} from '../../../hooks'
import { updatePartialSubscription } from '../../../actions'
import { ButtonProps } from '@veneer/core/dist/scripts/button/types'

type ExitButtonProps = {
  'data-analyticsid': string
  appearance: string
  result: string
  reminder?: boolean
  testid?: string
  screenName?: string
  disabled?: boolean
}

export const ExitButton: FC<ExitButtonProps & ButtonProps> = ({
  'data-analyticsid': dataAnalyticsid,
  appearance,
  result,
  reminder = false,
  testid = undefined,
  screenName = undefined,
  disabled = false,
  children
}) => {
  const dispatch = useDispatch()
  const partialSubscriptionState = usePartialSubscription()
  const exitFlow = useExitFlow()
  const isUnmounted = useIsUnmounted()
  const [loading, setLoading] = useState(false)

  const onContinue = useCallback(
    async (event) => {
      if (screenName !== undefined) {
        event.target['screenName'] = screenName
      }

      const reminder_code = () => (reminder ? 'cancel_with_reminder' : 'cancel')
      const completion_code = result === 'success' ? 'success' : reminder_code()
      setLoading(true)

      if (partialSubscriptionState.data?.id) {
        try {
          await dispatch(
            updatePartialSubscription({
              sign_up_completion_code: completion_code
            })
          )
        } catch {
          // do nothing
        }
      }

      try {
        await exitFlow(result, reminder ? { reminder } : {})
      } catch {
        // do nothing
      }

      if (!isUnmounted()) {
        setLoading(false)
      }
    },
    [
      dispatch,
      exitFlow,
      isUnmounted,
      partialSubscriptionState.data?.id,
      reminder,
      result,
      screenName
    ]
  )

  return (
    <Button
      className={disabled ? 'instant-ink-enroll-hpx-button-override' : ''}
      data-analyticsid={dataAnalyticsid}
      appearance={appearance}
      onClick={onContinue}
      loading={loading}
      data-testid={testid}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

import { useCallback } from 'react'
import { useGetText } from './useGetText'
import { useSettings } from '../stateData/hooks'

export const useApplyCodeErrorMessage = (supportPhone: string) => {
  const getText = useGetText()
  const {
    data: { showSupportPhone }
  } = useSettings()
  const supportUrl = getText('special_offers_box.support_url')

  return useCallback(
    ({
      error_code,
      error_params
    }: {
      error_code: string
      error_params?: Record<string, unknown>
    }) => {
      switch (error_code) {
        case 'expired_promotion_code':
        case 'stale_promotion_code':
        case 'expired':
          return getText('special_offers_box.messages.promo.expired')
        case 'inferior_promotion_code':
          return getText(
            'special_offers_box.messages.promo.inferior_promotion_code'
          )
        case 'inactive_promotion_code':
          return getText('special_offers_box.messages.promo.inactive', {
            startDate: error_params?.start_date,
            endDate: error_params?.end_date
          })
        case 'prepaid_key_already_entered_error':
          return getText('special_offers_box.messages.prepaid.already_entered')
        case 'already_redeemed_prepaid':
          return getText('special_offers_box.messages.prepaid.already_redeemed')
        case 'failed_to_apply':
          return getText('error_page.something_went_wrong')
        case 'already_entered_raf':
          return getText(
            'special_offers_box.messages.refer_a_friend.already_entered_html'
          )
        case 'printer_ineligible_raf':
          return getText(
            'special_offers_box.messages.refer_a_friend.printer_ineligible_html'
          )
        case 'wrong_region_raf':
        case 'invalid_region':
        case 'wrong_region':
          return getText(
            'special_offers_box.messages.refer_a_friend.wrong_region_html'
          )
        case 'promotion_code_requires_ek':
          return getText('special_offers_box.messages.promo.requires_ek_html')
        case 'already_redeemed':
          return showSupportPhone
            ? getText('special_offers_box.messages.ek.already_redeemed_html', {
                supportPhone
              })
            : getText(
                'special_offers_box.messages.ek.already_redeemed_no_phone_html',
                {
                  supportUrl
                }
              )
        case 'enrollment_key_already_entered_error':
          return showSupportPhone
            ? getText('special_offers_box.messages.ek.already_entered_html', {
                supportPhone
              })
            : getText(
                'special_offers_box.messages.ek.already_entered_no_phone_html',
                {
                  supportUrl
                }
              )
        case 'inactive':
          return showSupportPhone
            ? getText('special_offers_box.messages.inactive_html', {
                supportPhone
              })
            : getText('special_offers_box.messages.inactive_no_phone_html', {
                supportUrl
              })
        case 'additional_payment_required':
          return getText(
            'special_offers_box.messages.prepaid.additional_payment_required_html',
            { editBillingPath: error_params?.edit_billing_path }
          )
        case 'invalid_plan_guid':
        case 'generic_error':
          return getText('special_offers_box.messages.generic_error')
        default:
          return getText('shared.generic_error')
      }
    },
    [supportPhone, getText, showSupportPhone, supportUrl]
  )
}

export const hpxTokens = {
  royalBlue0: '#f5f6ff',
  royalBlue1: '#e5eaff',
  royalBlue2: '#d6ddff',
  royalBlue3: '#c4caff', //different from veneer
  royalBlue4: '#9ba6ff', //different from veneer
  royalBlue5: '#8190ff',
  royalBlue6: '#6374fd',
  royalBlue7: '#4759f5',
  royalBlue8: '#3543c0',
  royalBlue9: '#15218f', //different from veneer
  royalBlue10: '#28307b',
  royalBlue11: '#262b65',
  royalBlue12: '#242852',
  background: '#e0e1ee',
  secondaryText: '#191a22',
  secondaryBorder: '#828398'
}

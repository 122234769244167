import { SignupAction, SignupState } from '../types'
import { AxiosResponse } from 'axios'
import { AsyncDispatch } from '../hooks/useAsyncReducer'
import { updateStateData } from './updateStateData'

export const requestStateData = <T>(
  stateKey: string,
  getResponse: (
    signupState: SignupState,
    dispatch: AsyncDispatch<SignupAction, SignupState>
  ) => Promise<AxiosResponse<T>>
) => async (
  dispatch: AsyncDispatch<SignupAction, SignupState>,
  getState: () => SignupState
) => {
  try {
    await dispatch(
      updateStateData(stateKey, {
        loading: true,
        error: undefined,
        retry: undefined
      })
    )

    const response = await getResponse(getState(), dispatch)
    await dispatch(
      updateStateData(stateKey, {
        loading: false,
        data: response.data
      })
    )

    return response.data
  } catch (error) {
    await dispatch(
      updateStateData(stateKey, {
        loading: false,
        error: error,
        retry: () => dispatch(requestStateData(stateKey, getResponse))
      })
    )
    throw error
  }
}

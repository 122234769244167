import { useCallback } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import { useHistory } from 'react-router-dom'
import { useNextStage } from './useNextStage'
import { useStack } from './useStack'
import { useSignupSession } from './useSignupSession'
import { useDispatch } from './useDispatch'
import { exitSignupSession } from '../actions'
import { useHpAdminUrl } from './useHpAdminUrl'
import { useFlowContext } from './useFlowContext'
import { wait } from '../lib/helpers'

const logWarning = (stack: Stack, message?: string) => {
  if (stack != Stack.prod && message) {
    console.warn(message)
  }
}

const testAlert = (fromTest: boolean, stack: Stack, message: () => string) => {
  if (fromTest && stack != Stack.prod) {
    alert(message())
  }
}

const exitToInstantInk = (hpAdminUrl: string, initiator?: string) => {
  if (initiator === 'dashboard') {
    window.close()
    wait(2500).then(() => {
      window.location.replace(hpAdminUrl)
    })
  } else {
    window.location.replace(hpAdminUrl)
  }
}

export const useExitFlow = () => {
  const history = useHistory()
  const nextStage = useNextStage()
  const dispatch = useDispatch()
  const hpAdminUrl = useHpAdminUrl()
  const stack = useStack()
  const { exitFlowToInstantInk } = useFlowContext().getOptions()
  const {
    xCorrelationId,
    redirectUri,
    accountIdentifier,
    initiator,
    isODFlow,
    fromTest
  } = useSignupSession()

  return useCallback(
    async (result, output = {}, errorInfo = {}) => {
      logWarning(stack, output?.errorMessage)

      if (exitFlowToInstantInk) {
        await dispatch(exitSignupSession())
        exitToInstantInk(hpAdminUrl, initiator)
        return
      }

      if (!isODFlow) {
        await dispatch(exitSignupSession())
        testAlert(fromTest, stack, () =>
          JSON.stringify({
            result,
            output,
            errorInfo,
            xCorrelationId
          })
        )

        if (!redirectUri) {
          history.push('/')
          return
        }
      }

      if (redirectUri) {
        history.push(
          redirectUri,
          accountIdentifier ? { subscriptionId: accountIdentifier } : undefined
        )
      } else if (nextStage) {
        await nextStage?.call(null, {
          result,
          output,
          errorInfo,
          xCorrelationId
        })
      }
    },
    [
      stack,
      initiator,
      exitFlowToInstantInk,
      isODFlow,
      redirectUri,
      nextStage,
      dispatch,
      hpAdminUrl,
      history,
      accountIdentifier,
      xCorrelationId,
      fromTest
    ]
  )
}

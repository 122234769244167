export function loadScript({
  scriptId,
  scriptSrc,
  parent = 'head',
  async,
  defer
}: {
  scriptId: string
  scriptSrc: string
  parent?: string
  async?: boolean
  defer?: boolean
}) {
  if (!document.getElementById(scriptId)) {
    const scriptTag = document.createElement('script')
    scriptTag.src = scriptSrc
    scriptTag.id = scriptId
    if (null !== async) {
      scriptTag.async = async
    }
    if (null !== defer) {
      scriptTag.defer = defer
    }
    document.querySelector(parent).appendChild(scriptTag)
  }
}

export function waitFor(
  fn: () => Promise<void>,
  intervalMS: number,
  timeoutMS: number
): Promise<void> {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId)
      reject('timeout')
    }, timeoutMS)

    const intervalId = setInterval(async () => {
      try {
        await fn()
        clearInterval(intervalId)
        clearTimeout(timeoutId)
        resolve()
      } catch {
        //do nothing
      }
    }, intervalMS)
  })
}

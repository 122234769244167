import React from 'react'
import * as Styled from './skeletonStyles'
import { SkeletonText } from '../SkeletonComponents/SkeletonText'

export const PrinterCardSkeleton = () => {
  return (
    <Styled.PrinterCardSkeleton>
      <Styled.PrinterCardImageSkeleton />
      <SkeletonText height={24} marginBottom={8} />
      <SkeletonText height={16} width={180} marginBottom={16} />
      <SkeletonText height={40} marginBottom={32} />
    </Styled.PrinterCardSkeleton>
  )
}

import React, { FC, useMemo } from 'react'
import { Router } from 'react-router-dom'
import { SignupContext } from '../../contexts'
import { useAsyncReducer } from '../../hooks'
import { signupInitializer, signupReducer } from '../../reducers'
import { FlowContextProvider } from '../../flows/shared/FlowContextProvider'
import { AnalyticsEventHandler } from '../../flows/shared/AnalyticsEventHandler'
import { TranslationProvider } from '../../flows/shared/TranslationProvider'
import { UserLocaleProvider } from '../../flows/shared/UserLocaleProvider'
import { SessionContainer } from '../../flows/shared/SessionContainer'
import { FlowManagerProvider } from '../../flows/shared/FlowManagerProvider'
import { AnalyticsProvider } from '../../flows/shared/AnalyticsProvider'
import { SignupSkeleton } from './signupSkeleton'
import { SignupProps } from '../../types'
import { SessionData } from '../../lib/sessionData'
import styled, { css } from 'styled-components'
import '@veneer/styles/typography/typography.css'
import { isMobile } from '../../lib/screenHelpers'
import { withContainerSize } from '../../flows/shared/ContainerSizeProvider'
import { LayoutTheme } from '../../flows/shared/LayoutTheme/layoutTheme'

type SignupProviderProps = {
  signupProps: SignupProps
  sessionData: SessionData
}

const StyledSignupContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  min-height: 100vh;
  z-index: 0;
  overflow: hidden;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      min-height: 100%;
    `}
`
const StyledSignupContainerWithSize = withContainerSize(StyledSignupContainer)

export const SignupProvider: FC<SignupProviderProps> = ({
  signupProps,
  sessionData,
  children
}) => {
  const { navigation } = signupProps
  const [state, dispatch] = useAsyncReducer(
    signupReducer,
    null,
    signupInitializer(signupProps, sessionData)
  )
  const signupContext = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <SignupContext.Provider value={signupContext}>
      <StyledSignupContainerWithSize>
        <LayoutTheme>
          <SignupSkeleton />
          <FlowContextProvider>
            <Router history={navigation}>
              <TranslationProvider>
                <UserLocaleProvider>
                  <SessionContainer>
                    <FlowManagerProvider>
                      <AnalyticsProvider>
                        <AnalyticsEventHandler>
                          {children}
                        </AnalyticsEventHandler>
                      </AnalyticsProvider>
                    </FlowManagerProvider>
                  </SessionContainer>
                </UserLocaleProvider>
              </TranslationProvider>
            </Router>
          </FlowContextProvider>
        </LayoutTheme>
      </StyledSignupContainerWithSize>
    </SignupContext.Provider>
  )
}

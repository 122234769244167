import { AddressResponse, Address } from '../../types'

function addressFixture({
  Address = '16399 West Bernardo Drive',
  AddressSummary = 'San Diego, CA 92127',
  City = 'San Diego',
  State = 'CA',
  Country = 'US',
  subBuilding = [],
  ZipCode = '92127',
  PostalCodeSecondary = []
} = {}): Address {
  return {
    Address,
    AddressSummary,
    City,
    State,
    Country,
    subBuilding,
    ZipCode,
    PostalCodeSecondary
  }
}

export function addressResponseFixture({
  ResultCode = 'XS02',
  Results = [
    addressFixture(),
    addressFixture({
      Address: '1636 Del Sur Boulevard',
      AddressSummary: 'San Diego, CA 92173',
      City: 'San Diego',
      State: 'CA',
      Country: 'US',
      subBuilding: [],
      ZipCode: '92173',
      PostalCodeSecondary: []
    }),
    addressFixture({
      Address: '1631 Precision Park Lane',
      AddressSummary: 'San Ysidro, CA 92173',
      City: 'San Ysidro',
      State: 'CA',
      Country: 'US',
      subBuilding: [],
      ZipCode: '92173',
      PostalCodeSecondary: []
    }),
    addressFixture({
      Address: '1630 Broadway',
      AddressSummary: 'Chula Vista, CA 91911',
      City: 'Chula Vista',
      State: 'CA',
      Country: 'US',
      subBuilding: [],
      ZipCode: '91911',
      PostalCodeSecondary: []
    })
  ]
} = {}): AddressResponse {
  return {
    ResultCode,
    Results
  }
}

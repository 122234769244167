import React, { FC, useRef } from 'react'
import { SignupProps } from '../../types'
import { SignupProvider } from './signupProvider'
import { FlowManagerRoutes } from '../../flows/shared/FlowManagerRoutes'
import { AbTesting } from '../../flows/shared/AbTesting'
import { SessionData } from '../../lib/sessionData'

export const Signup: FC<SignupProps> = (props) => {
  const sessionData = useRef(new SessionData('InstantInkSignupSession')).current
  return (
    <SignupProvider signupProps={props} sessionData={sessionData}>
      <AbTesting />
      <FlowManagerRoutes />
    </SignupProvider>
  )
}

import { useCallback } from 'react'
import { useSignupSession } from './useSignupSession'
import { useSignupState } from './useSignupState'

export const useUpdateEnrollTracker = () => {
  const { partialSubscription } = useSignupState()
  const { instantInkService } = useSignupSession()

  return useCallback(
    async (params: { terms_consent?: boolean }) => {
      try {
        await instantInkService.updateEnrollTracker(
          partialSubscription?.data?.id,
          params
        )
      } catch (error) {
        throw {
          error: error
        }
      }
    },
    [instantInkService, partialSubscription?.data?.id]
  )
}

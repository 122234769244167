import { useCallback } from 'react'
import { useSignupState } from './useSignupState'
import { useDispatch } from './useDispatch'
import { fetchBenefits, updatePartialSubscription } from '../actions'
import { useSignupSession } from './useSignupSession'

export const useApplyCode = () => {
  const dispatch = useDispatch()
  const { partialSubscription } = useSignupState()
  const { instantInkService } = useSignupSession()

  return useCallback(
    async (code) => {
      let offerType
      const validOfferTypes = [
        'promo',
        'prepaid',
        'free',
        'purchased',
        'refer_a_friend'
      ]

      try {
        const {
          data: { offer_type }
        } = await instantInkService.fetchCodeType(
          partialSubscription?.data?.id,
          code
        )
        offerType = offer_type
      } catch (error) {
        throw {
          error_code: error?.response?.data?.error_code,
          error_params: error?.response?.data?.error_params
        }
      }

      if (!validOfferTypes.includes(offerType)) {
        throw { error_code: 'generic_error' }
      }

      try {
        await dispatch(updatePartialSubscription({ special_offers_code: code }))
        await dispatch(fetchBenefits())
        return { offerType }
      } catch (error) {
        throw { error_code: 'failed_to_apply' }
      }
    },
    [dispatch, partialSubscription, instantInkService]
  )
}

import React, { useState, useEffect, useMemo, FC } from 'react'
import { UserLocaleContext } from '../../../contexts'
import { UserLocale } from '../../../types'
import {
  useUserAccount,
  useTranslation,
  useSignupSession,
  useSettings
} from '../../../hooks'
import { getUserLocale } from '../../../lib/localeHelpers'
import { LoadingPage } from '../LoadingPage'
import { ErrorPage } from '../ErrorPage'
import { filterStateErrors, isStateEmpty } from '../../../lib/stateHelpers'

export const UserLocaleProvider: FC = ({ children }) => {
  const userAccount = useUserAccount()
  const { flow, showSkeleton } = useSignupSession()
  const translation = useTranslation()
  const settingsState = useSettings()
  const [userLocale, setUserLocale] = useState<UserLocale>(null)
  const country = useMemo(
    () => settingsState?.data?.preferredLocale.split('_')[1],
    [settingsState]
  )
  const language = useMemo(
    () => settingsState?.data?.preferredLocale.split('_')[0],
    [settingsState]
  )
  const apiStates = [settingsState]
  const apiStatesErrors = filterStateErrors(apiStates)
  const shouldRenderErrorPage = userAccount.error || apiStatesErrors.length > 0
  const shouldRenderChildren =
    userLocale && !userAccount.error && !isStateEmpty(apiStates)
  const shouldSetUserLocale = userLocale === null && translation && country

  useEffect(() => {
    if (shouldSetUserLocale) {
      const locale = getUserLocale(language, country)
      setUserLocale(locale)
      translation.setLocale(locale.language, locale.country)
    }
  }, [translation, country, language, flow, shouldSetUserLocale])

  if (shouldRenderChildren) {
    return (
      <UserLocaleContext.Provider value={userLocale}>
        {children}
      </UserLocaleContext.Provider>
    )
  }

  if (shouldRenderErrorPage) {
    return <ErrorPage stateErrors={[userAccount, ...apiStatesErrors]} />
  }

  return showSkeleton ? null : <LoadingPage />
}

import { useState, useEffect } from 'react'

export const useAssetImport = (assetReference: string) => {
  const [mfe, setMfe] = useState<System.Module>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (assetReference) {
      setIsLoading(true)
      System.import(assetReference)
        .then((response) => {
          setMfe(response)
        })
        .catch(() => {
          setMfe(undefined)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setMfe(undefined)
      setIsLoading(false)
    }
  }, [assetReference])

  return {
    mfe,
    isLoading
  }
}

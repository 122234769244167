import React, { FC, useEffect, useState } from 'react'
import { useSignupState } from '../../../hooks'
import { AnalyticsContext } from '../../../contexts'
import { analyticsClientFactory } from '../../../lib/analyticsClients'
import { AnalyticsClient } from '../../../types'

export const AnalyticsProvider: FC = ({ children }) => {
  const signupState = useSignupState()
  const [analyticsClient, setAnalyticsClient] = useState<AnalyticsClient>(null)

  useEffect(() => {
    if (!analyticsClient) {
      setAnalyticsClient(analyticsClientFactory(signupState))
    } else {
      analyticsClient.updateState(signupState)
    }
  }, [analyticsClient, signupState])

  if (!analyticsClient) {
    return null
  }

  return (
    <AnalyticsContext.Provider value={analyticsClient}>
      {children}
    </AnalyticsContext.Provider>
  )
}

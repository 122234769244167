import promisePoller from 'promise-poller'

async function checkClaimStatus({ fetchPrinterClaimStatus }) {
  const claimStatus = await fetchPrinterClaimStatus()

  const success = claimStatus === 'claimed'

  if (claimStatus === 'pending') {
    throw 'pending'
  }

  return { success, claimStatus }
}

export default class PrinterClaimStatusPoller {
  fetchPrinterClaimStatus: () => void
  partialSubscriptionId: string
  interval: number
  timeout: number
  poller: ({
    taskFn,
    retries,
    interval,
    masterTimeout
  }) => { success: boolean; claimStatus: string }

  constructor({ fetchPrinterClaimStatus, interval, timeout }) {
    this.fetchPrinterClaimStatus = fetchPrinterClaimStatus
    this.interval = interval * 1000
    this.timeout = timeout * 1000
    this.poller = promisePoller
  }

  async poll(): Promise<{ success: boolean; claimStatus: string }> {
    return this.poller({
      taskFn: () =>
        checkClaimStatus({
          fetchPrinterClaimStatus: this.fetchPrinterClaimStatus
        }),
      retries: Infinity,
      interval: this.interval,
      masterTimeout: this.timeout
    })
  }
}

import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchDeeplinkInfo = () =>
  requestStateData(
    'deeplinkInfo',
    ({ signupSession: { instantInkService } }: SignupState) =>
      instantInkService.fetchDeeplinkInfo()
  )

export const useDeeplinkInfo = () =>
  useStateData('deeplinkInfo', fetchDeeplinkInfo)

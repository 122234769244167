import { useCallback, useMemo } from 'react'
import { useGetText } from './useGetText'
import { usePartialSubscription } from '../stateData/hooks'

export const useApplyCodeSuccessMessage = () => {
  const getText = useGetText()
  const { prepaidKeyCodes, enrollmentKeyCode } = usePartialSubscription().data
  const codeCount = useMemo(
    () => prepaidKeyCodes.length + (enrollmentKeyCode ? 1 : 0),
    [prepaidKeyCodes, enrollmentKeyCode]
  )

  return useCallback(
    ({ offerType }: { offerType: string }) => {
      switch (offerType) {
        case 'promo':
          return getText('special_offers_box.messages.promo.success')
        case 'prepaid':
        case 'purchased':
        case 'free':
          return getText(
            `special_offers_box.messages.${
              codeCount > 1 ? 'success_with_multiple' : 'success'
            }`
          )
        case 'refer_a_friend':
          return getText('special_offers_box.messages.refer_a_friend.success')
        default:
          return ''
      }
    },
    [codeCount, getText]
  )
}

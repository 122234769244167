import React, { useState, useEffect, FC, useMemo, useCallback } from 'react'
import i18next, { i18n } from 'i18next'
import { TranslationContext } from '../../../contexts'
import { getTranslations } from '../../../assets/locale'
import { LoadingPage } from '../LoadingPage'
import { useSignupSession } from '../../../hooks'

const injectPluralKeys = (translations: Record<string, string>) => {
  Object.keys(translations).forEach((key) => {
    if (typeof translations[key] === 'string') {
      if (key.endsWith('_other')) {
        ;['_two', '_few', '_many'].forEach((suffix) => {
          const pluralKey = key.replace('_other', suffix)
          if (translations[pluralKey] === undefined) {
            translations[pluralKey] = translations[key]
          }
        })
      }
    } else {
      injectPluralKeys((translations[key] as unknown) as Record<string, string>)
    }
  })
  return translations
}

const createI18n = async (language: string, country: string) => {
  const i18n = i18next.createInstance()
  const translations = await getTranslations(language, country)
  await i18n.init({
    returnObjects: true,
    lng: language,
    defaultNS: country,
    resources: {
      [language]: {
        [country]: injectPluralKeys(translations)
      }
    }
  })
  return i18n
}

export const TranslationProvider: FC = ({ children }) => {
  const [i18n, seti18n] = useState<i18n>(null)
  const { showSkeleton } = useSignupSession()
  const setLocale = useCallback(
    (language: string, country: string) =>
      createI18n(language, country).then((result) => seti18n(result)),
    [seti18n]
  )
  const context = useMemo(
    () => ({
      t: i18n?.t,
      exists: i18n?.exists,
      setLocale
    }),
    [i18n, setLocale]
  )

  useEffect(() => {
    if (!i18n) {
      createI18n('en', 'US').then((result) => seti18n(result))
    }
  }, [i18n])

  if (i18n) {
    return (
      <TranslationContext.Provider value={context}>
        {children}
      </TranslationContext.Provider>
    )
  }

  return showSkeleton ? null : <LoadingPage />
}

import { requestStateData } from './requestStateData'
import { clearStateData } from './clearStateData'
import { SignupState } from '../types'

export const fetchValidations = () =>
  requestStateData(
    'validations',
    ({
      signupSession: { instantInkService },
      partialSubscription
    }: SignupState) =>
      instantInkService.fetchValidations(partialSubscription?.data?.id)
  )

export const clearValidations = () => clearStateData('validations')

import React, { FC, useState, useEffect } from 'react'
import { Flow } from '../../../types'
import { FlowContext } from '../../../contexts'
import { useSignupSession } from '../../../hooks'
import { flowFactory } from '../../../lib/flows'
import { ThemeProvider } from 'styled-components'
import { LoadingPage } from '../LoadingPage'
import { useTheme as useVeneerTheme } from '@veneer/theme'
import {
  hpxThemeVariables,
  defaultThemeVariables
} from '../LayoutTheme/themeVariables'
import { HpxGlobalStyleOverride } from '../HpxGlobalStyleOverride'

export const FlowContextProvider: FC = ({ children }) => {
  const { flow, basePath, showSkeleton, isHpx } = useSignupSession()
  const [flowContext, setFlowContext] = useState<Flow>(undefined)
  const { color } = useVeneerTheme()

  useEffect(() => {
    if (flowContext === undefined) {
      ;(async () => {
        setFlowContext(await flowFactory({ flow, basePath }))
      })()
    }
  }, [flow, basePath, flowContext])

  if (flowContext) {
    const themeVariables = isHpx
      ? hpxThemeVariables(color)
      : defaultThemeVariables
    const theme = {
      color,
      isHpx,
      ...themeVariables,
      ...flowContext.getTheme()
    }

    return (
      <FlowContext.Provider value={flowContext}>
        <ThemeProvider theme={theme}>
          <>
            <HpxGlobalStyleOverride />
            {children}
          </>
        </ThemeProvider>
      </FlowContext.Provider>
    )
  }

  return showSkeleton ? null : <LoadingPage />
}

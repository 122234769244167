import { ShippingAddress } from '../../types'

export function shippingAddressFixture({
  id = '1',
  supportMultiShipping = true,
  firstName = 'John',
  lastName = 'Doe',
  company = 'HP Inc.',
  phoneNumber1 = '18581234567',
  street1 = '16399 W Bernardo Dr',
  street2 = undefined,
  city = 'San Diego',
  state = 'CA',
  zipCode = '92127',
  country = 'US',
  supportedServices = ['instant_ink', 'instant_paper']
} = {}): ShippingAddress {
  return {
    id,
    supportMultiShipping,
    firstName,
    lastName,
    company,
    phoneNumber1,
    street1,
    street2,
    city,
    state,
    zipCode,
    country,
    supportedServices
  }
}

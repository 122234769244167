import { useCallback, useRef } from 'react'
import { WhenJWebReady } from '@jarvis/jweb-core'
import { wait } from '../lib/helpers'

export const useDeviceHttpProxy = () => {
  const deviceHttpProxy = useRef(null)
  const sendDHPRequest = useCallback(
    (options) => {
      if (!deviceHttpProxy.current?.sendRequest) {
        return wait(500).then(sendDHPRequest.bind(null, options))
      }
      try {
        return deviceHttpProxy.current.sendRequest(options)
      } catch (e) {
        console.log(e)
      }
    },
    [deviceHttpProxy]
  )

  const initializeDHP = useCallback(async () => {
    const response = await WhenJWebReady
    deviceHttpProxy.current = response.Plugins.DeviceHttpProxy
    if (!deviceHttpProxy.current) {
      throw new Error('JWeb plugin: DeviceHttpProxy not available')
    }
  }, [])

  return {
    initializeDHP,
    sendDHPRequest
  }
}

import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchPrinterDetails = () =>
  requestStateData(
    'printerDetails',
    ({ signupSession: { stratusService } }: SignupState) =>
      stratusService.getPrinterDetails()
  )

export const usePrinterDetails = () =>
  useStateData('printerDetails', fetchPrinterDetails)

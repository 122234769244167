const getLedmResourceUri = (discoveryTree, type) => {
  try {
    const supportedTrees = discoveryTree
      .getElementsByTagName('ledm:DiscoveryTree')[0]
      .getElementsByTagName('ledm:SupportedTree')
    let uri = null
    for (const tree of supportedTrees) {
      const resource = tree.getElementsByTagName('dd:ResourceType')[0]
      if (resource.textContent === type) {
        uri = tree.getElementsByTagName('dd:ResourceURI')[0].textContent
        break
      }
    }
    return uri
  } catch (e) {
    console.error(`Not able to find resource uri for ${type} type - ${e}`)
    console.error(discoveryTree)
    return null
  }
}

const getCdmHref = ({ servicesDiscovery, resourceGun, rel }) => {
  try {
    const service = servicesDiscovery['services'].find(
      (service) => service['serviceGun'] === resourceGun
    )
    return service['links'].find((link) => link['rel'] === rel).href
  } catch (e) {
    console.error(`Not able to find action href for rel ${rel} - ${e}`)
    console.error(servicesDiscovery)
    return null
  }
}

export const getConsumableConfigDynHref = (discoveryTree) => {
  return getLedmResourceUri(discoveryTree, 'ledm:hpLedmConsumableConfigDyn')
}

export const getSuppliesPublicHref = (servicesDiscovery) => {
  return getCdmHref({
    servicesDiscovery,
    resourceGun: 'com.hp.cdm.service.supply.version.1',
    rel: 'suppliesPublic'
  })
}

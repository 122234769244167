import { useEffect, useState } from 'react'
import { useDispatch } from './useDispatch'
import { updatePartialSubscription } from '../actions'
import { usePlans, usePartialSubscription } from '../stateData/hooks'
import { Plan } from '../types'
import { filterStateErrors, isStateEmpty } from '../lib/stateHelpers'

export const useSelectDefaultInkPlan = () => {
  const partialSubscriptionState = usePartialSubscription()
  const plansState = usePlans()
  const dispatch = useDispatch()
  const [initialPlan, setInitialPlan] = useState<Plan>(undefined)
  const [currentPlan, setCurrentPlan] = useState<Plan>(undefined)

  useEffect(() => {
    if (
      initialPlan === undefined &&
      currentPlan === undefined &&
      partialSubscriptionState.data &&
      plansState.data
    ) {
      setInitialPlan(partialSubscriptionState.data.plan)

      if (!partialSubscriptionState.data.plan) {
        const inkPlans = plansState.data.filter((plan) =>
          plan.program?.includes('ink')
        )
        const defaultInkPlan =
          inkPlans.find((plan) => plan.isDefault) || inkPlans[0]
        ;(async () => {
          await dispatch(
            updatePartialSubscription({
              plan_id: defaultInkPlan.id
            })
          )
          setCurrentPlan(defaultInkPlan)
        })()
      } else {
        setCurrentPlan(partialSubscriptionState.data.plan)
      }
    }
  }, [
    currentPlan,
    dispatch,
    initialPlan,
    partialSubscriptionState.data,
    plansState.data
  ])

  const APIStates = [partialSubscriptionState, plansState]
  if (isStateEmpty(APIStates)) {
    return { isLoading: true }
  }

  const stateErrors = filterStateErrors(APIStates)
  if (stateErrors.length > 0) {
    return { stateErrors }
  }

  return { initialPlan, currentPlan }
}

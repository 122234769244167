import { requestStateData } from './requestStateData'
import { SignupState } from '../types'

export const createPreenrollment = () =>
  requestStateData(
    'preenrollment',
    ({
      signupSession: { instantInkService },
      partialSubscription
    }: SignupState) =>
      instantInkService.createPreenrollment(partialSubscription?.data?.id)
  )

import { ContainerSize } from '../types'

const { MOBILE, TABLET_PORTRAIT, TABLET_LANDSCAPE, DESKTOP } = ContainerSize

export const isSmallScreen = (containerSize: ContainerSize) =>
  [MOBILE, TABLET_PORTRAIT].includes(containerSize)

export const isLargeScreen = (containerSize: ContainerSize) =>
  [TABLET_LANDSCAPE, DESKTOP].includes(containerSize)

export const isMobile = (containerSize: ContainerSize) =>
  containerSize === MOBILE

export const isDesktop = (containerSize: ContainerSize) =>
  containerSize === DESKTOP

export const isTabletLandscape = (containerSize: ContainerSize) =>
  containerSize === TABLET_LANDSCAPE

export const isTabletPortrait = (containerSize: ContainerSize) =>
  containerSize === TABLET_PORTRAIT

import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { clearStateData } from './clearStateData'
import { Plan, SignupState } from '../types'
import { updateStateData } from './updateStateData'

export const fetchPlans = () =>
  requestStateData(
    'plans',
    async (
      {
        signupSession: { instantInkService },
        isLoggedIn,
        localization,
        partialSubscription
      }: SignupState,
      dispatch
    ) => {
      const response = await instantInkService.fetchPlans(
        partialSubscription?.data?.id,
        ...(isLoggedIn ? [] : [localization?.language, localization?.country])
      )
      const { plans, abTestPlans } = response.data.reduce(
        (acc, plan) => {
          acc[plan.obsolete ? 'abTestPlans' : 'plans'].push(plan)
          return acc
        },
        { plans: [] as Plan[], abTestPlans: [] as Plan[] }
      )
      await dispatch(updateStateData('abTestPlans', { data: abTestPlans }))
      response.data = plans
      return response
    }
  )

export const clearPlans = () => clearStateData('plans')

export const usePlans = () =>
  useStateData(
    'plans',
    fetchPlans,
    ({ partialSubscription }) => partialSubscription?.data?.id !== undefined
  )

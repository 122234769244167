import { AxiosResponse } from 'axios'
import { ActiveUser, PrinterDetails, UserAccount } from '../../types'
import { IStratusService } from '../../lib/stratusService'
import { SessionData } from '../../lib/sessionData'
import { resolve, getMockFixtureOverride } from './mockHelpers'

const activeUserFixture = ({
  email = {
    value: 'user@email.com',
    verified: true
  },
  givenName = 'John',
  familyName = 'Smith'
} = {}): ActiveUser => ({
  email,
  givenName,
  familyName
})

const userAccountFixture = ({
  regionId = 'US',
  language = 'en',
  type = 'BusinessTransactionalSMB'
} = {}): UserAccount => ({
  regionId,
  language,
  type
})

const printerDetailsFixture = ({
  identity = {
    friendlyName: 'Printer Name',
    location: 'HP headquarters',
    deviceUuid: 'device-uuid-1'
  }
} = {}): PrinterDetails => ({
  identity
})

export class MockStratusService implements IStratusService {
  sessionData: SessionData
  defaultDelay: number

  constructor(sessionData: SessionData, defaultDelay = 0) {
    this.sessionData = sessionData
    this.defaultDelay = defaultDelay
  }

  getActiveUser(): Promise<AxiosResponse<ActiveUser>> {
    return resolve(
      {
        data: {
          ...activeUserFixture(),
          ...getMockFixtureOverride(this.sessionData, 'activeUser')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  getUserAccount(): Promise<AxiosResponse<UserAccount>> {
    return resolve(
      {
        data: {
          ...userAccountFixture(),
          ...getMockFixtureOverride(this.sessionData, 'userAccount')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  getPrinterDetails(): Promise<AxiosResponse<PrinterDetails[]>> {
    return resolve(
      {
        data: [
          {
            ...printerDetailsFixture(),
            ...getMockFixtureOverride(this.sessionData, 'printerDetails')
          }
        ],
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }
}

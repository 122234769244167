import React from 'react'
import { extendSemantics } from '@veneer/semantics'
import primitives from '@veneer/primitives'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'
import { hpxTokens } from './hpxTokens'
import { useSignupSession } from '../../../hooks'

export const MODE = 'light'
export const DENSITY = 'standard'

export const shape = (isHpx: boolean) => {
  return isHpx ? 'pill' : 'round'
}

export const customSemantics = (isHpx: boolean) => {
  const HpxTheme = extendSemantics({
    color: {
      light: {
        background: {
          disabled: hpxTokens.royalBlue3, // disabled primary background
          default: hpxTokens.background
        },
        foreground: {
          disabled: primitives.color.gray5 // disabled primary text and border, also disabled secondary text and border
          // disabled: hpxTokens.royalBlue4 // gets correct disabled secondary button, but cannot see text as well in disabled primary button
        },
        primary: {
          foreground: {
            default: primitives.color.white // primary text
          },
          base: {
            default: hpxTokens.royalBlue7, // primary background
            hover: hpxTokens.royalBlue8, // primary hover, secondary border and font
            active: hpxTokens.royalBlue9 // primary click
          },
          background: {
            hover: hpxTokens.background, // secondary background hover
            active: hpxTokens.royalBlue3 // secondary background click
          }
        }
      }
    }
  })

  return isHpx ? HpxTheme : extendSemantics({})
}

export const LayoutTheme = ({ children }) => {
  const { isHpx } = useSignupSession()

  return (
    <div className="layouttheme">
      <VeneerThemeProvider
        customSemantics={customSemantics(isHpx)}
        mode={MODE}
        shape={shape(isHpx)}
        density={DENSITY}
      >
        {children}
      </VeneerThemeProvider>
    </div>
  )
}

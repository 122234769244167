import { useContext, useMemo } from 'react'
import { AnalyticsContext } from '../contexts'

export const useAnalytics = () => {
  const analyticsClient = useContext(AnalyticsContext)
  return {
    publishClickEvent: useMemo(
      () =>
        analyticsClient
          ? analyticsClient.publishClickEvent.bind(analyticsClient)
          : () => null,
      [analyticsClient]
    ),
    publishDisplayEvent: useMemo(
      () =>
        analyticsClient
          ? analyticsClient.publishDisplayEvent.bind(analyticsClient)
          : () => null,
      [analyticsClient]
    ),
    publishCustomEvent: useMemo(
      () =>
        analyticsClient && analyticsClient.publishCustomEvent
          ? analyticsClient.publishCustomEvent.bind(analyticsClient)
          : () => null,
      [analyticsClient]
    )
  }
}

export enum StepNames {
  START_STEP = 'START_STEP',
  VALUE_PROP_STEP = 'VALUE_PROP_STEP',
  AFU_STEP = 'AFU_STEP',
  ENROLL_STEP = 'ENROLL_STEP',
  PRE_ENROLL_STEP = 'PRE_ENROLL_STEP',
  PRINTER_ENROLL_STEP = 'PRINTER_ENROLL_STEP',
  FTM_ENROLL_STEP = 'FTM_ENROLL_STEP',
  FTM_SHIPPING_STEP = 'FTM_SHIPPING_STEP',
  THANK_YOU_STEP = 'THANK_YOU_STEP',
  FTM_THANK_YOU_STEP = 'FTM_THANK_YOU_STEP',
  PRINTER_SELECTION_STEP = 'PRINTER_SELECTION_STEP',
  DEEPLINK_STEP = 'DEEPLINK_STEP',
  CONNECT_STEP = 'CONNECT_STEP',
  PROGRESS_BAR_STEP = 'PROGRESS_BAR_STEP',
  REPLACEMENT_VALUE_PROP_STEP = 'REPLACEMENT_VALUE_PROP_STEP',
  REPLACEMENT_CONFIRMATION_STEP = 'REPLACEMENT_CONFIRMATION_STEP',
  ACCOUNT_SELECTION_STEP = 'ACCOUNT_SELECTION_STEP',
  REPLACEMENT_THANK_YOU_STEP = 'REPLACEMENT_THANK_YOU_STEP',
  MANUAL_PRINTER_CLAIM_STEP = 'MANUAL_PRINTER_CLAIM_STEP',
  REPLACEMENT_MANUAL_PRINTER_CLAIM_STEP = 'REPLACEMENT_MANUAL_PRINTER_CLAIM_STEP',
  AUTOFILL_ADDRESS = 'AUTOFILL_ADDRESS',
  UNSUPPORTED_OS = 'UNSUPPORTED_OS',
  ENABLE_WEB_SERVICES_STEP = 'ENABLE_WEB_SERVICES_STEP'
}

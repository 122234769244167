import { useSignupSession } from './useSignupSession'
import { v3HpAdminPath } from '../lib/urlConsts'
import { useFlowContext } from './useFlowContext'

export const useHpAdminUrl = () => {
  const { instantInkURL } = useSignupSession()
  const { exitFlowToInstantInk } = useFlowContext().getOptions()

  return exitFlowToInstantInk
    ? instantInkURL + v3HpAdminPath
    : window.location.origin
}

import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { clearStateData } from './clearStateData'
import { SignupState } from '../types'

export const fetchPrinters = () =>
  requestStateData(
    'printers',
    ({ signupSession: { instantInkService } }: SignupState) =>
      instantInkService.fetchPrinters()
  )

export const clearPrinters = () => clearStateData('printers')

export const usePrinters = () => useStateData('printers', fetchPrinters)

import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const PrinterSliderSkeleton = styled.div`
  display: inline-flex;
  background-color: inherit;
`

export const PrinterCardSkeleton = styled.div`
  box-sizing: border-box;
  width: 360px;
  padding: 40px;
  height: 352px;
  border-radius: 16px;
  background-color: ${tokens.color.gray2};
  margin-right: 24px;
  flex-shrink: 0;
`
export const PrinterCardImageSkeleton = styled.div`
  width: 177px;
  height: 116px;
  margin-bottom: 16px;
  background-color: ${tokens.color.gray3};
  border-radius: 8px;
`

export const PrinterSelectionStepSkeleton = styled.div`
  max-width: 952px;
  width: 100%;
`

export const getQueryParam = (
  search: string,
  key: string,
  defaultValue?: string
) => new URLSearchParams(search).get(key) || defaultValue

export const addQueryParam = (
  path: string,
  key?: string,
  value?: string
): string => {
  const url = new URL(path, window.location.origin)
  url.searchParams.append(key, value)
  return `${url.pathname}${url.search}${url.hash}`
}

export const removeQueryParam = (path: string, key: string): string => {
  const url = new URL(path, window.location.origin)
  ;['?' + key, key].forEach((k) => url.searchParams.delete(k))
  return `${url.pathname}${url.search}${url.hash}`
}

import { requestStateData } from './requestStateData'
import { clearStateData } from './clearStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchBenefits = () =>
  requestStateData(
    'benefits',
    ({
      signupSession: { instantInkService },
      partialSubscription
    }: SignupState) =>
      instantInkService.fetchBenefits(partialSubscription?.data?.id)
  )

export const clearBenefits = () => clearStateData('benefits')

export const useBenefits = () =>
  useStateData(
    'benefits',
    fetchBenefits,
    ({ partialSubscription }) => partialSubscription?.data?.id !== undefined
  )

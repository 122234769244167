import { useEffect, useRef, useReducer, useCallback } from 'react'
import { useIsUnmounted } from './useIsUnmounted'

export declare type AsyncAction<A, S, T = unknown> = (
  dispatch: AsyncDispatch<A, S>,
  getState?: () => S
) => Promise<T>

export declare type AsyncDispatch<A, S> = (
  action: A | AsyncAction<A, S>
) => Promise<unknown>

export const useAsyncReducer = <S, A>(
  reducer: (prevState: S, action: A) => S,
  initialState: S,
  initializer?: (arg: S) => S
): [S, AsyncDispatch<A, S>] => {
  const [state, dispatch] = useReducer(reducer, initialState, initializer)
  const stateRef = useRef(state)
  const isUnmounted = useIsUnmounted()

  useEffect(() => {
    stateRef.current = state
  }, [state])

  const waitForUseEffect = () =>
    new Promise((resolve) => setTimeout(resolve, 0))

  const asyncDispatch = useCallback(
    async (action: A | AsyncAction<A, S>) => {
      if (typeof action === 'function') {
        return (action as AsyncAction<A, S>)(
          asyncDispatch,
          () => stateRef.current
        )
      }
      if (!isUnmounted()) {
        dispatch(action)
        await waitForUseEffect()
      }
    },
    [isUnmounted]
  )

  return [state, asyncDispatch]
}

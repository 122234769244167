export { benefitsFixture, replacementBenefitsFixture } from './benefitsFixtures'
export { billingInfoFixture, billingAddressFixture } from './billingFixtures'
export { codeTypeFixture } from './codeTypeFixtures'
export { deeplinkInfoFixture } from './deeplinkFixtures'
export { partialSubscriptionFixture } from './partialSubscriptionFixtures'
export { pathsFixture } from './pathsFixtures'
export {
  planFixture,
  paperAddonFixture,
  inkPlanFixture,
  inkPlansFixture,
  tonerPlanFixture,
  tonerPlansFixture,
  smbTonerPlanFixture,
  smbTonerPlansFixture,
  smbColorTonerPlansFixture
} from './planFixtures'
export { printerFixture } from './printerFixtures'
export { printerBenefitsFixture } from './printerBenefitsFixtures'
export { settingsFixture } from './settingsFixtures'
export { shippingAddressFixture } from './shippingFixtures'
export { subscriptionFixture } from './subscriptionFixtures'
export { validationsFixture } from './validationsFixtures'
export { bannerFixture } from './bannerFixtures'
export { printerOfferFixture } from './printerOfferFixtures'
export { connectAndVerifyFixture } from './connectAndVerifyFixtures'
export { addressResponseFixture } from './melissaFixtures'

import * as Styled from './skeletonStyles'
import { SkeletonText, SkeletonIcon } from '../SkeletonComponents'
import React from 'react'

export const NavbarSkeleton = () => (
  <Styled.NavbarSkeleton data-testid="skeleton-navbar">
    <SkeletonText
      marginBottom={0}
      backgroundColor="none"
      width={202}
      height={32}
      className="shell-skeleton"
    />

    <Styled.NavbarMenuSkeleton>
      {[1, 2, 3].map((row) => (
        <Styled.NavbarMenuButtonGroupSkeleton key={`skeleton-${row}`}>
          <SkeletonIcon marginRight={8} size={20} className="shell-skeleton" />
          <SkeletonText
            marginBottom={0}
            backgroundColor="none"
            width={80}
            height={16}
            className="shell-skeleton"
          />
        </Styled.NavbarMenuButtonGroupSkeleton>
      ))}
    </Styled.NavbarMenuSkeleton>
  </Styled.NavbarSkeleton>
)

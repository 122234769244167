import React, { FC } from 'react'
import { useSettings } from '../../stateData/settings'
import { MfeComponentContext, MfeComponentProps } from './types'
import { MfeLoader } from './mfeLoader'
import { ComponentLoader } from './componentLoader'

export const MfeComponent: FC<MfeComponentProps> = (props) => {
  const settingsState = useSettings()
  const loadAsMfe = settingsState.data?.jarvisComponentsToLoadAsMfe?.includes(
    props.name
  )
  return (
    <MfeComponentContext.Provider value={props}>
      {loadAsMfe ? <MfeLoader /> : <ComponentLoader />}
    </MfeComponentContext.Provider>
  )
}

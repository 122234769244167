import { ColorSemantics } from '@veneer/semantics'
import primitives from '@veneer/primitives'
import { hpxTokens } from './hpxTokens'

export const hpxThemeVariables = (color: ColorSemantics) => ({
  cardBoxShadow: 'none',
  checkmarkIconColor: color.primary.base.default,
  valuePropPadding: '0',
  valuePropPaddingTop: '0',
  enrollStepSpacing: '0',
  enrollCardsMargin: '12px 0 0 0',
  enrollButtonsMarginTop: '64px',
  modalPadding: '40px 24px 24px',
  modalPaddingMobile: '40px 16px 16px',
  modalTitleMarginRight: '30px',
  thankYouMargin: '0',
  exitCardBoxShadow: 'none',
  offlineWarningWidth: '100%',
  offlineWarningBorder: '12px',
  offlineWarningBackground: '#ffedde',
  offlineWarningDirection: 'column',
  offlineWarningMarginBottom: '4px',
  offlineWarningFlex: 'flex-start',
  offlineWarningFontSize: '14px',
  printerSelectionStepBackgroundColor: hpxTokens.background,
  modalTitle: `${primitives.typography.size7};`,
  modalFontColor: hpxTokens.secondaryText,
  modalTitleLineHeight: `${primitives.typography.lineHeight7}`,
  modalContentFontSize: `${primitives.typography.size2}`,
  changePrinterModalTitlePadding: '32px',
  changePrinterCancelButtonMargin: '0 0 8px',
  buttonGroupMargin: '0',
  cancelButtonMargin: '8px 0 0 0'
})

export const defaultThemeVariables = {
  cardBoxShadow: '0 4px 16px #21212119',
  checkmarkIconColor: 'black',
  valuePropPadding: '0 16px',
  valuePropPaddingTop: '40px',
  enrollStepSpacing: '8px',
  enrollCardsMargin: '10px 0',
  enrollButtonsMarginTop: '30px',
  modalPadding: '40px 48px',
  modalPaddingMobile: '40px 48px',
  modalTitleMarginRight: '0',
  thankYouMargin: '0 16px',
  exitCardBoxShadow: `0 4px 16px ${primitives.color.gray3}`,
  offlineWarningWidth: '368px',
  offlineWarningBorder: '16px',
  offlineWarningBackground: `${primitives.color.darkOrange5}19`,
  offlineWarningDirection: 'row',
  offlineWarningMarginBottom: '0',
  offlineWarningFlex: 'flex-end',
  offlineWarningFontSize: '12px',
  printerSelectionStepBackgroundColor: '#f8f8f8',
  modalTitle: `${primitives.typography.size5};`,
  modalFontColor: '#212121',
  modalTitleLineHeight: `${primitives.typography.lineHeight5}`,
  modalContentFontSize: `${primitives.typography.size1}`,
  changePrinterModalTitlePadding: '16px',
  changePrinterButtonMargin: '0 0 16px',
  buttonGroupMargin: '16px 0 0',
  cancelButtonMargin: '16px 0 0 0'
}

import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchBillingAddress = () =>
  requestStateData(
    'billingAddress',
    ({ signupSession: { comfeService } }: SignupState) =>
      comfeService.getBillingAddress()
  )

export const useBillingAddress = () =>
  useStateData('billingAddress', fetchBillingAddress)

import React, { FC } from 'react'
import * as Styled from './styles'
import * as SkeletonStyled from './skeletonStyles'
import { StyledStepperAndScrollableArea } from '../../shared/StepperAndScrollableArea/styles'
import { PrinterSelectionStepSkeleton } from '../../shared/PrinterSelectionStep/printerSelectionStepSkeleton'
import { NavbarSkeleton } from '../../shared/Navbar/navbarSkeleton'
import { V3StepperSkeleton } from '../V3Stepper/v3StepperSkeleton'
import styled from 'styled-components'

const StyledSkeletonArea = styled.div`
  && {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`

export const V3ContainerSkeleton: FC = () => (
  <StyledSkeletonArea>
    <Styled.V3Container data-testid="v3-container-skeleton">
      <NavbarSkeleton />
      <StyledStepperAndScrollableArea>
        <V3StepperSkeleton />
        <SkeletonStyled.ContentAreaSkeleton>
          <PrinterSelectionStepSkeleton />
        </SkeletonStyled.ContentAreaSkeleton>
      </StyledStepperAndScrollableArea>
    </Styled.V3Container>
  </StyledSkeletonArea>
)

import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'
import { Button } from '../Button'
import { ExitButton } from '../ExitButton'
import { useAnalytics, useFlowContext, useGetText } from '../../../hooks'

const Container = styled.div``

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 320px;
`

const ErrorText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ErrorTitle = styled.h3`
  margin: 40px 0 32px 0;
`

const ErrorSubtitle = styled.p`
  font-size: 16px;
`

const ButtonsSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  button:first-child {
    margin-right: 16px;
  }
`

type ErrorPageProps = {
  stateErrors: {
    error?: unknown
    retry?: () => Promise<unknown>
  }[]
}

export const ErrorPage: FC<ErrorPageProps> = ({ stateErrors = [] }) => {
  const getText = useGetText()
  const flowContext = useFlowContext()
  const { showErrorPageSkipButton } = flowContext.getOptions()
  const { Layout } = flowContext.getComponents()
  const { publishDisplayEvent } = useAnalytics()
  const isUnderMaintenance = stateErrors.find(
    (state) => state.error?.['response']?.status === 502
  )

  useEffect(() => {
    publishDisplayEvent({ screenName: 'ErrorPage' })
  }, [publishDisplayEvent])

  const onRetry = () => {
    Promise.all(stateErrors.map(({ error, retry }) => error && retry?.())).then(
      () => null
    )
  }

  return (
    <Layout className="instantink-signup-layout">
      <Container>
        <ContentSection>
          <IconMinusCircle size={48} color="red7" />
          <ErrorText>
            <ErrorTitle>
              {getText(
                `error_page.${
                  isUnderMaintenance ? 'maintenance' : 'something_went_wrong'
                }`
              )}
            </ErrorTitle>
            <ErrorSubtitle>
              {getText(
                `error_page.${
                  isUnderMaintenance ? 'maintenance_subtitle' : 'try_again'
                }`
              )}
            </ErrorSubtitle>
          </ErrorText>
        </ContentSection>
        <ButtonsSection>
          {showErrorPageSkipButton ? (
            <ExitButton
              data-analyticsid="Skip"
              appearance="secondary"
              result="failure"
              screenName="ErrorPage"
            >
              {getText('error_page.skip')}
            </ExitButton>
          ) : null}
          <Button
            appearance="primary"
            onClick={(event) => {
              event.target['screenName'] = 'ErrorPage'
              onRetry()
            }}
            data-analyticsid="TryAgain"
          >
            {getText('error_page.try_again_button')}
          </Button>
        </ButtonsSection>
      </Container>
    </Layout>
  )
}

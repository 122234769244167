import { tokens } from '@veneer/tokens'

const { xsMax, smMax, mdMax, lgMax } = tokens.layout

export enum WindowSize {
  MOBILE_MAX = parseInt(xsMax, 10),
  TABLET_PORTRAIT_MAX = parseInt(smMax, 10),
  TABLET_LANDSCAPE_MAX = parseInt(mdMax, 10),
  LAPTOP_MAX = parseInt(lgMax, 10)
}

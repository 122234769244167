import {
  OnboardingType,
  SignupAction,
  SignupActionType,
  SignupProps,
  SignupSession,
  SignupState
} from '../types'
import { MockInstantInkService } from '../mock/lib/mockInstantInkService'
import { InstantInkService } from '../lib/instantInkService'
import { MockComfeService } from '../mock/lib/mockComfeService'
import { ComfeService } from '../lib/comfeService'
import { MockStratusService } from '../mock/lib/mockStratusService'
import { StratusService } from '../lib/stratusService'
import { Stack } from '@jarvis/web-stratus-client'
import { SessionData } from '../lib/sessionData'
import { getQueryParam } from '../lib/urlHelpers'
import { createCorrelationId } from '../lib/stringHelpers'

const createServices = ({
  authProvider,
  stack,
  xCorrelationId,
  instantInkURL,
  comfeURL,
  mockStratus,
  sessionData
}) => ({
  instantInkService: mockStratus
    ? new MockInstantInkService(sessionData)
    : new InstantInkService(instantInkURL, xCorrelationId, authProvider),
  comfeService: mockStratus
    ? new MockComfeService(sessionData)
    : new ComfeService(comfeURL, xCorrelationId, authProvider),
  stratusService: mockStratus
    ? new MockStratusService(sessionData)
    : new StratusService(stack, authProvider)
})

const saveSignupSession = (
  sessionData: SessionData,
  signupSession: SignupSession
) => {
  sessionData.set({
    ...signupSession,
    instantInkService: undefined,
    comfeService: undefined,
    stratusService: undefined
  })
}

const defaultInstantInkURL = (stack: Stack) =>
  ({
    [Stack.stage]: 'https://instantink-stage1.hpconnectedstage.com',
    [Stack.prod]: 'https://instantink.hpconnected.com'
  }[stack] || 'https://instantink-pie1.hpconnectedpie.com')

const defaultComfeURL = (stack: Stack) =>
  ({
    [Stack.stage]: 'https://comfe-stage1.instantink.com/api/comfe/v1',
    [Stack.prod]: 'https://comfe.instantink.com/api/comfe/v1'
  }[stack] || 'https://comfe-pie1.instantink.com/api/comfe/v1')

const DeviceKeysToPrinterKeys = {
  uuid: 'printerUuid',
  bizModel: 'printerBizModel',
  productNumber: 'printerSku',
  serialNumber: 'printerSerialNumber',
  fwProtocolCapability: 'printerProtocolCapability',
  modelName: 'printerDisplayName',
  highestResImgUrl: 'printerImageUrl'
}
const DeviceKeys = Object.keys(DeviceKeysToPrinterKeys)
const PrinterKeys = Object.values(DeviceKeysToPrinterKeys)
const deviceToPrinterKey = (deviceKey: string) =>
  DeviceKeysToPrinterKeys[deviceKey]
const clearPrinterKeys = (signupSession: SignupSession) => {
  PrinterKeys.forEach((key) => {
    delete signupSession[key]
  })
}

const updateSignupSessionFromOnboardingSession = (
  onboarding: OnboardingType,
  signupSession: SignupSession
): boolean => {
  let newSession = false
  const { device, xCorrelationId, currentServiceId } = onboarding.sessionContext

  if (device) {
    DeviceKeys.forEach((deviceKey) => {
      const printerKey = deviceToPrinterKey(deviceKey)
      if (device[deviceKey] && device[deviceKey] != signupSession[printerKey]) {
        signupSession[printerKey] = device[deviceKey]
        newSession = true
      }
    })
  }

  if (xCorrelationId) {
    if (signupSession.xCorrelationId != xCorrelationId) {
      signupSession.xCorrelationId = xCorrelationId
      newSession = true
    }
  }

  signupSession.currentServiceId = currentServiceId
  return newSession
}

export const restoreSignupSession = (
  signupProps: SignupProps,
  sessionData: SessionData
): SignupSession => {
  localStorage.removeItem('selectedAddress')
  const {
    authProvider,
    stack,
    navigation: { location },
    properties,
    onboarding,
    sessionInterface
  } = signupProps
  const isLoggedIn = sessionInterface.isLoggedIn()
  const signupSession: SignupSession = {
    basePath: location.pathname,
    instantInkURL: defaultInstantInkURL(stack),
    comfeURL: properties?.comfeBaseUrl || defaultComfeURL(stack),
    mockStratus: false,
    forcePlanModal: true,
    ...sessionData.get()
  }

  const redirectUri = getQueryParam(
    location.search,
    'redirectUri',
    location.state?.['smbDashboardRedirectUrl']
  )
  const analyticsBaseScreenPathName = getQueryParam(
    location.search,
    'analyticsBaseScreenPathName'
  )

  const { basePath, partialSubscriptionId } = signupSession
  let newSession = partialSubscriptionId === undefined

  if (redirectUri) {
    signupSession.redirectUri = redirectUri
  } else if (newSession && isLoggedIn) {
    delete signupSession.redirectUri
  }

  if (analyticsBaseScreenPathName) {
    signupSession.analyticsBaseScreenPathName = analyticsBaseScreenPathName
  } else if (newSession) {
    delete signupSession.analyticsBaseScreenPathName
  }

  if (basePath && !location.pathname.startsWith(basePath)) {
    signupSession.basePath = location.pathname
  }

  signupSession.xCorrelationId = getQueryParam(
    location.search,
    'xCorrelationId',
    signupSession.xCorrelationId
  )

  if (onboarding?.sessionContext) {
    if (updateSignupSessionFromOnboardingSession(onboarding, signupSession)) {
      newSession = true
    }
  } else if (signupSession.fromTest) {
    delete signupSession.currentStep
  } else if (signupSession.basePath === location.pathname) {
    newSession = true
    clearPrinterKeys(signupSession)
    signupSession.printerUuid = getQueryParam(location.search, 'printerUUID')
    signupSession.fromHpidValueProp = getQueryParam(
      location.search,
      'fromHpidValueProp'
    )
  }

  if (!signupSession.xCorrelationId) {
    signupSession.xCorrelationId = createCorrelationId()
  }

  if (newSession) {
    delete signupSession.accountIdentifier
    delete signupSession.partialSubscriptionId
    delete signupSession.flipConverted
    delete signupSession.printerReplaced
    delete signupSession.currentStep
    delete signupSession.showShippingModal
    signupSession.forcePlanModal = true
  }

  signupSession.isODFlow = !!onboarding?.sessionContext?.device
  signupSession.showSkeleton = properties?.showSkeleton
  signupSession.flow = properties?.flow
  saveSignupSession(sessionData, signupSession)

  const { xCorrelationId, instantInkURL, comfeURL, mockStratus } = signupSession

  return {
    ...signupSession,
    ...createServices({
      authProvider: isLoggedIn ? authProvider : undefined,
      stack,
      xCorrelationId,
      instantInkURL,
      comfeURL,
      mockStratus,
      sessionData
    })
  }
}

export const signupSession = (
  signupState: SignupState,
  action: SignupAction
) => {
  const { START_SIGNUP_SESSION, UPDATE_SIGNUP_SESSION } = SignupActionType
  const {
    isLoggedIn,
    authProvider,
    stack,
    signupSession,
    sessionData
  } = signupState
  let newState: SignupSession

  if (action.type === START_SIGNUP_SESSION) {
    const {
      xCorrelationId,
      instantInkURL,
      comfeURL,
      mockStratus
    } = signupSession
    newState = {
      ...signupSession,
      ...createServices({
        authProvider: isLoggedIn ? authProvider : undefined,
        stack,
        xCorrelationId,
        instantInkURL,
        comfeURL,
        mockStratus,
        sessionData
      })
    }
  }

  if (action.type === UPDATE_SIGNUP_SESSION) {
    newState = {
      ...signupSession,
      ...(action.signupSession as SignupSession)
    }
  }

  if (newState) {
    saveSignupSession(sessionData, newState)
    return newState
  }

  return signupSession
}

import {
  SignupAction,
  SignupActionType,
  SignupProps,
  SignupState,
  StateData
} from '../types'
import { restoreSignupSession, signupSession } from './signupSession'
import { SessionData } from '../lib/sessionData'

export const signupInitializer = (
  signupProps: SignupProps,
  sessionData: SessionData
) => (): SignupState => {
  const {
    analytics,
    authProvider,
    stack,
    properties,
    localization,
    userSession,
    sessionInterface,
    onboarding,
    serviceRouting
  } = signupProps
  return {
    analytics,
    authProvider,
    stack,
    properties,
    localization,
    isLoggedIn: sessionInterface.isLoggedIn(),
    userSession,
    sessionInterface,
    onboarding,
    serviceRouting,
    sessionData,
    signupSession: restoreSignupSession(signupProps, sessionData),
    partialSubscription: {},
    subscription: {},
    shippingAddress: {},
    benefits: {},
    billingInfo: {},
    billingAddress: {},
    activeUser: {},
    printerDetails: {},
    userAccount: {},
    plans: {},
    abTestPlans: {},
    printers: {},
    settings: {},
    paths: {},
    preenrollment: {},
    deeplinkInfo: {},
    validations: {},
    printerOffer: {},
    banner: {}
  }
}

export const signupReducer = (
  state: SignupState,
  action: SignupAction
): SignupState => ({
  ...state,
  signupSession: signupSession(state, action),
  ...(action.type === SignupActionType.SET_STATE_DATA &&
  action.stateKey &&
  action.stateData &&
  state[action.stateKey]
    ? {
        [action.stateKey]: {
          ...state[action.stateKey],
          ...(action.stateData as StateData<unknown>)
        }
      }
    : {})
})

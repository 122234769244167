import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { ShippingAddress, SignupState } from '../types'
import { AxiosResponse } from 'axios'

export const fetchShippingAddress = () =>
  requestStateData(
    'shippingAddress',
    async ({
      signupSession: { comfeService },
      partialSubscription
    }: SignupState) => {
      const deviceId = partialSubscription?.data?.printer?.deviceId
      if (deviceId) {
        return await comfeService.getShippingAddressByCloudId(deviceId)
      }
      const response = await comfeService.getShippingAddress()
      return {
        ...response,
        data: response.data || {}
      } as AxiosResponse<ShippingAddress>
    }
  )

export const useShippingAddress = () =>
  useStateData('shippingAddress', fetchShippingAddress)

import React, { FC } from 'react'
import * as Styled from './skeletonStyles'
import { SkeletonText } from '../SkeletonComponents/SkeletonText'
import { PrinterCardSkeleton } from './PrinterCardSkeleton'

export const PrinterSelectionStepSkeleton: FC = () => {
  return (
    <Styled.PrinterSelectionStepSkeleton data-testid="printer-selection-step-skeleton">
      <SkeletonText marginTop={8} className="shell-skeleton" />
      <Styled.PrinterSliderSkeleton className="shell-skeleton">
        <PrinterCardSkeleton />
        <PrinterCardSkeleton />
      </Styled.PrinterSliderSkeleton>
    </Styled.PrinterSelectionStepSkeleton>
  )
}
